import moment from "moment";

function getColor(status) {
  switch (status) {
  case "contrato_assinado":
  case "pronta_transmissao":
    return "blue";
  case "devolvida":
    return "deep-orange";
  case "implantada":
    return "green";
  case "finalizada":
    return "lime";
  default:
    return "grey";
  }
};

function last90Days() {
  var today = new Date();
  var priorDate = new Date(new Date().setDate(today.getDate() - 90))
  return moment(priorDate).format('DD/MM/YYYY')
};

export default {
    SET_NOTIFICACOES(state, payload) {
      const notificacoesSininho = [];
      let count = 0;
      const NOVENTA_DIAS_ATRAS = moment(last90Days(), 'DD/MM/YYYY');
      const notificacoes = payload.notificacoes.map((notificacao) => {
        return {
          ...notificacao,
          cor: getColor(notificacao.statusProposta),
        }
      });
      const notificacoesFiltradas = notificacoes.filter(n => {
        const date = moment(n.dataAtualizacao, 'DD/MM/YYYY')
        return moment(date).isAfter(NOVENTA_DIAS_ATRAS)
      })
      notificacoesFiltradas.forEach((notificacao) => {
        if (!notificacao.visto) {
          count++;
          notificacoesSininho.push(notificacao)
        }
      });

      state.notificacoes = notificacoes;
      state.count = count;
      state.notificacoesSininho = notificacoesSininho;
    },
    SET_LOADING(state, payload) {
      state.loading = payload.loading;
    }
};
