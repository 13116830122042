class GerenciadorDeStep {
    constructor(idadeTitular, possuiPlanoOpcional = false, possuiPortabilidade = false) {
        this.idadeTitular = idadeTitular;
        this.possuiPlanoOpcional = possuiPlanoOpcional;
        this.possuiPortabilidade = possuiPortabilidade;
        this.steps = [
            "Dados Pessoais",
            "Planos",
            "Dados Titular",
            "Dados Responsavel Legal",
            "Dados Dependentes",
            "Dados Portabilidade",
            "Dados Produtos Opcionais",
            "Documentos",
        ];
        this.etapas = [
            { tela: "Dados Pessoais", etapa: "basicos" },
            { tela: "Planos", etapa: "planos" },
            { tela: "Dados Titular", etapa: "dados_titular_contratacao" },
            { tela: "Dados Responsavel Legal", etapa: "dados_responsavel_contratacao" },
            { tela: "Dados Dependentes", etapa: "dependentes_contratacao" },
            { tela: "Dados Portabilidade", etapa: "" },
            { tela: "Dados Produtos Opcionais", etapa: "" },
            { tela: "Documentos", etapa: "anexo" },
        ]
    }

    retornarSteps() {
        const existeTelaResponsavelLegal = this.idadeTitular < 18;
        if (!existeTelaResponsavelLegal) {
            this.steps = this.steps.filter(step => step !== "Dados Responsavel Legal");
            this.etapas = this.etapas.filter(etapa => etapa.tela !== "Dados Responsavel Legal");
        }
        if (!this.possuiPlanoOpcional) {
            this.steps = this.steps.filter(step => step !== "Dados Produtos Opcionais");
            this.etapas = this.etapas.filter(etapa => etapa.tela !== "Dados Produtos Opcionais");
        }
        if (!this.possuiPortabilidade) {
            this.steps = this.steps.filter(step => step !== "Dados Portabilidade");
            this.etapas = this.etapas.filter(etapa => etapa.tela !== "Dados Portabilidade");
        }

        return this.steps;
    }

    retornaProximaEtapa(telaAtual, etapaAtual) {
        const indexEtapaAtual = this.etapas.findIndex(etapa => etapa.etapa === etapaAtual);
        const indexTelaAtual = this.etapas.findIndex(etapa => etapa.tela === telaAtual);
        if (indexEtapaAtual > indexTelaAtual + 1) {
            return null;
        }
        return this.etapas[indexTelaAtual + 1].etapa;
    }

    retornarTelaComBaseEtapaAtual(etapaAtual) {
        if (etapaAtual === "clonada" || etapaAtual === "clonada_sem_supervisor" || etapaAtual === "devolvida") 
            return 0

        const telaAtual = this.etapas.find(etapa => etapa.etapa === etapaAtual).tela;
        return this.steps.findIndex(step => step === telaAtual);
    }
}

export default GerenciadorDeStep;