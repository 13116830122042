<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">
              Planos
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Dados contratação
            </h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="verFiltro(planos)" class="mr-4">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
      <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
    </div>
    <v-container class="pa-5 pt-0" v-else>
      <v-row>
        <v-col class="pb-2">
          <p class="text-center subtitle-2 grey--text mt-2 mb-0 font-weight-regular">{{ planos.length }} registro(s) encontrados(s)</p>
        </v-col>
      </v-row>
      <v-row class="pb-15">
        <v-col class="col-12 col-md-6" v-for="(plano, index) in planos" :key="index">
          <base-card-details-plan
            :index="index"
            :logo="plano.operadoraLogo"
            :nome="plano.nomePlanoAns"
            :abrangencia="plano.abrangencia"
            :acomodacao="plano.acomodacao"
            :coparticipacao="plano.coparticipacao"
            :modeloCoparticipacao="plano.modeloCoparticipacao"
            :odonto="plano.comOdonto"
            :valorTotalOdonto="Number(plano.valorTotalOdontoAdicional)"
            :operadora="plano.nomeExibicaoOperadora"
            :segmentacao="plano.segmentacao"
            :valor="plano.valorTotal"
            :valorSemDesconto="plano.valorTotalSemDesconto"
            :loadingSelecionar="loadingSelecionar"
            :valorProximaTabela="plano.valorTotalProximaTabelaPreco"
            :dataInicioProximaTabela="plano.dataInicioProximaTabela"
            :captacao="plano.captacao"
            @selecionar="selecionarPlano(plano)"
            @detalhes="verDetalhes(plano)"
          ></base-card-details-plan>
        </v-col>
      </v-row>
    </v-container>
    <dialog-filtro :filtros="filtros" :planos="planos" :UF="estado" @filtrarplanos="carregaFiltro" @limparfiltro="limpaFiltro" v-model="dialogFiltro" @close="dialogFiltro = false"></dialog-filtro>
    <dialog-plano :plano="planoSelected" @selecionarplano="selecionarPlano" v-model="dialogPlano" @close="dialogPlano = false"></dialog-plano>
    <v-bottom-navigation  :height="70" light scroll-threshold fixed v-if="selectedPlano && selectedPlano.idProdutoFatura">
      <v-row align-content="end">
        <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class="pa-4">
          <p>Plano selecionado:</p>
        </v-col>
        <v-col cols="8" xs="8" sm="8" md="8" lg="8" xl="8" class="pa-0">
          <v-list-item>
            <v-list-item-avatar dark size="50" tile class="mr-2 mt-0 mb-0">
              <v-img width="100%" contain :src="selectedPlano.operadoraLogo || ''"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="subtitle-2 mt-1 text-wrap text-uppercase"
                style="width: 180px; line-height: 1.3">{{ selectedPlano.nomePlanoAns || '' }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ma-0">
              <div class="d-flex">
                <v-chip color="primary" class="secundaryBtn--text ma-2" dark label>
                  <span class="mt-1 text-uppercase">{{ selectedPlano.valorTotal || 0 | currency }}</span>
                </v-chip>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </v-bottom-navigation>
  </div>
</template>

<script>
import planoService from '@/services/planoService';
import propostaService from '@/services/propostaService';
import BaseCardDetailsPlan from '@/components/BaseCardDetailsPlan.vue';
import DialogPlano from '@/views/AreaLogada/CriarProposta/Planos/DialogPlano.vue';
import DialogFiltro from '@/views/AreaLogada/CriarProposta/Planos/DialogFiltro.vue';
import { mapState, mapActions } from 'vuex';
import filters from '@/filters';

export default {
  name: 'Planos',
  components: { BaseCardDetailsPlan, DialogPlano, DialogFiltro },
  data() {
    return {
      planos: [],
      estado: '',
      ordenar: true,
      planoSelected: null,
      loading: false,
      dialogPlano: false,
      dialogFiltro: false,
      filtros: {},
      aplicarFiltro: false,
      planosSemFiltro: [],
      loadingSelecionar: false,
      form: {
        operadora: false,
      },
    };
  },
  computed: {
    ...mapState({
      operadoraState: (state) => state.PME.operadora,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
      dadosDependentesState: (state) => state.PME.dependentes,
      dadosVigenciaState: (state) => state.PME.vigencia,
      corratoraState: (state) => state.PME.corretora,
      planoState: (state) => state.PME.plano,
      dadosState: (state) => state.PME,
      dadosTitularState: (state) => state.cadastroProposta.titular,
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    selectedPlano() {
      if(this.planosState && this.planoState.idProdutoFatura)
        return this.planos.find((item) => item.idProdutoFatura === this.planoState.idProdutoFatura);
      return {}
    },
  },
  methods: {
    ...mapActions({
      setPlanoPme: 'PME/setPlano',
      setPlano: 'cadastroProposta/setPlano',
      setOperadora: 'cadastroProposta/setOperadora',
      setCorretora: 'cadastroProposta/setCorretora',
      setDadosAdministradora: 'cadastroProposta/setDadosAdministradora',
      setDadosProposta: 'cadastroProposta/setDadosProposta',
      setDadosTitular: "cadastroProposta/setDadosTitular",
      setDadosDependentes: "cadastroProposta/setDadosDependentes",
      setVigencia: "cadastroProposta/setVigencia",
      verificaReajustePlano: "cadastroProposta/setVerificarReajustePlano",
    }),
    carregaFiltro(value) {
      const filter = [];
      let planosfiltered = this.planosSemFiltro;
      if (value.campos.abrangencia) filter.push('value.campos.abrangencia == obj.abrangencia');
      if (value.campos.segmentacao) filter.push('value.campos.segmentacao == obj.segmentacao');
      if (value.campos.acomodacao) filter.push('value.campos.acomodacao == obj.acomodacao');
      if (value.nomePlano) filter.push('(obj.nomePlanoAns || "").toLowerCase().includes(value.nomePlano.toLowerCase())');
      if (value.campos.coparticipacao) {
        if (value.campos.coparticipacao === 'Sim') {
          value.campos.coparticipacao = true;
        } else {
          value.campos.coparticipacao = false;
        }
        filter.push('value.campos.coparticipacao == obj.coparticipacao');
      }

      if (value.hospitais && Object.values(value.hospitais).length) {
        const array = Object.values(value.hospitais);
        let arrIdProdutos = [];

        array.forEach((el) => {
          arrIdProdutos = [...arrIdProdutos, ...el];
        });
        planosfiltered = planosfiltered.filter((plano) => arrIdProdutos.includes(plano.idProdutoFatura.toString()));
      }

      if (filter.length) {
        planosfiltered = this.planosSemFiltro.filter(
          // eslint-disable-next-line no-eval
          (obj) => eval(filter.join(' && ')),
        );
      }

      if (value.campos.ordenar && value.campos.ordenar === 'Por nome') {
        planosfiltered.sort((a, b) => a.nomePlano.localeCompare(b.nomePlano));
      }

      if (value.campos.ordenar && value.campos.ordenar === 'Por Preço') {
        planosfiltered.sort((a, b) => parseFloat(a.valorTotal) - parseFloat(b.valorTotal));
      }
      if (value.rangePreco && value.rangePreco.length) {
        const objFiltros = {
          valorMinimo: value.rangePreco[0],
          valorMaximo: value.rangePreco[1],
        };
        planosfiltered = planosfiltered.filter((plano) => plano.valorTotal < objFiltros.valorMaximo && plano.valorTotal > objFiltros.valorMinimo);
      }

      this.aplicarFiltro = true;
      this.planos = planosfiltered;
      this.dialogFiltro = false;
    },

    limpaFiltro() {
      this.dialogFiltro = false;
      this.planos = this.planosSemFiltro;
    },

    async loadData() {
      this.setAdministradora("QUALICORP ADMINISTRADORA")
      let data;
      try {
        const handleProposta = {
          async obterPlanos(context) {
            context.loading = true;
            const enderecoIndex = context.propostaState.titular.enderecos.findIndex((f) => f.tipo.findIndex((x) => x === 'ENDERECO_RESIDENCIAL') >= 0);
            let enderecoResidencial = {};
            if (enderecoIndex >= 0) {
              enderecoResidencial = context.propostaState.titular.enderecos[enderecoIndex];
            }
            context.estado = enderecoResidencial.estado;
            const beneficiarios = filters.mapBeneficiario(context.propostaState);
            
            return planoService.getPlanosPorProposta({
              publicoAlvo: process.env.VUE_APP_PROFISSAO_PME,
              entidade: process.env.VUE_APP_ENTIDADE_PME,
              uf: enderecoResidencial.estado,
              cidade: enderecoResidencial.cidade,
              operadora: context.operadoraState.nome,
              cpfCnpjCorretora: context.corratoraState.corretora.cpfCnpj,
              beneficiarios,
              administradora: context.corratoraState.corretora.administradoras,
              dataVigencia: context.dadosVigenciaState,
              portabilidade: context.corratoraState.portabilidade,
            });
          },
        };

        data = await handleProposta.obterPlanos(this);

        this.popularPlanos(data);
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
      } finally {
        this.loading = false;
      }
    },

    setAdministradora(Administradora) {
      let administradoraPlano;
      if (Administradora && ['QUALICORP ADMINISTRADORA', 'QUALICORP'].includes(Administradora)) {
        administradoraPlano = 'qualicorp';
      }

      if (Administradora && ['Uniconsult'].includes(Administradora)) {
        administradoraPlano = 'uniconsult';
      }

      if (Administradora && ['CEMIG SAUDE'].includes(Administradora)) {
        administradoraPlano = 'cemig';
      }

      if (Administradora && ['Plural Administradora'].includes(Administradora)) {
        administradoraPlano = 'plural';
      }

      if (Administradora && ['Elo'].includes(Administradora)) {
        administradoraPlano = 'elo';
      }

      if (Administradora && ['Aliança'].includes(Administradora)) {
        administradoraPlano = 'alianca';
      }

      
      if (administradoraPlano) {
        this.setDadosAdministradora(administradoraPlano)
      }

      return administradoraPlano || Administradora;
    },

    popularPlanos(data) {
      data.sort((a, b) => parseFloat(b.valorTotal) - parseFloat(a.valorTotal));

      this.planosSemFiltro = data;
      this.planos = data.map((plano) => {
        if (plano.beneficiarios[0] && plano.beneficiarios[0].proximaTabelaPreco) {
          const { dataInicio } = plano.beneficiarios[0].proximaTabelaPreco;
          const dataSeparada = dataInicio.split('-');
          return {
            ...plano,
            dataInicioProximaTabela: `${dataSeparada[1]}/${dataSeparada[0]}`,
          };
        }
        return {
          ...plano,
        };
      });
    },

    verDetalhes(plano) {
      this.planoSelected = plano;
      this.dialogPlano = true;
    },

    verFiltro(planos) {
      if (Object.keys(this.filtros).length === 0) {
        const filtroAbrangencia = [];

        const uniqueAbrangencia = [...new Set(planos.map((item) => item.abrangencia))];

        uniqueAbrangencia.forEach((abrangencia) => {
          filtroAbrangencia.push({
            nome: abrangencia,
            id: abrangencia,
          });
        });

        this.filtros.dataAbrangencia = filtroAbrangencia;

        const filtroSegmentacao = [];

        const uniqueSegmentacao = [...new Set(planos.map((item) => item.segmentacao))];

        uniqueSegmentacao.forEach((segmetacao) => {
          filtroSegmentacao.push({
            nome: segmetacao,
            id: segmetacao,
          });
        });

        this.filtros.dataSegmentacao = filtroSegmentacao;

        const filtroAcomodacao = [];

        const uniqueAcomodacao = [...new Set(planos.map((item) => item.acomodacao))];

        uniqueAcomodacao.forEach((acomodacao) => {
          filtroAcomodacao.push({
            nome: acomodacao,
            id: acomodacao,
          });
        });
        this.filtros.dataAcomodacao = filtroAcomodacao;
      }

      this.dialogFiltro = true;
    },
    async selecionarPlano(plano) {
      this.setPlano({
        ...plano,
        idProdutoFatura: plano.idProdutoFatura,
        nomePlanoAns: plano.nomePlanoAns,
        valorTotal: plano.valorTotal,
        possuiPlanosOpcionais: plano.possuiPlanosOpcionais != null ? plano.possuiPlanosOpcionais : false,
        administradora: this.setAdministradora(plano.administradora),
      });

      this.setPlanoPme({
        ...plano,
        idProdutoFatura: plano.idProdutoFatura,
        nomePlanoAns: plano.nomePlanoAns,
        valorTotal: plano.valorTotal,
        possuiPlanosOpcionais: plano.possuiPlanosOpcionais != null ? plano.possuiPlanosOpcionais : false,
        administradora: this.setAdministradora(plano.administradora),
      });
      this.setDadosProposta({...this.propostaState})

      try {
        this.loadingSelecionar = true;

        await this.salvarDados();
        const resp = await this.verificarCartaReajuste({dataVigencia: this.dadosState.vigencia});
        if (!resp) return;

        this.$emit('next');
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao salvar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingSelecionar = false;
      }
    },
    async verificarCartaReajuste({ dataVigencia }) {
      const resp = await this.verificaReajustePlano(dataVigencia);
      if (!resp) {
        const textReajuste = await this.$root.$confirmdialog.open("Atenção", `O plano selecionado sofrerá reajuste na data de vigência escolhida.`);
        return textReajuste;
      }
      return true;
    },
    async salvarDados() {
      if (this.idLead) {
        this.dadosState.idLead = this.idLead;
      }
      this.setCorretora({corretora: this.corratoraState.corretora})
      this.$emit("atualizarEtapa");
      const dadosProposta = JSON.parse(JSON.stringify(this.propostaState));
      dadosProposta.titular = {
        ...dadosProposta.titular,
        entidade: process.env.VUE_APP_ENTIDADE_PME,
      }
      const [data] = await propostaService.addOrEdit(dadosProposta.guid, dadosProposta);

      await propostaService.vinculaPropostaPme(data.guid, this.dadosState.guid)

      const deps = data.dependentes.map(({ parentesco, ...resto }) => ({
        ...resto,
        parentesco: (parentesco && parentesco.nome) || "",
      }));
      // Grava dados das telas anteriores
      this.setOperadora(this.operadoraState.nome)
      this.setDadosTitular({
        ...this.dadosTitularState,
        publicoAlvo: process.env.VUE_APP_PROFISSAO_PME,
        entidade: process.env.VUE_APP_ENTIDADE_PME,
        pisPasep: (data && data.titular && data.titular.pisPasep) || "",
        guid: data.titular.guid,
      });
      this.setDadosDependentes(deps);
      this.setCorretora({corretora: data.corretora})
      this.setVigencia({
        ...data.vigencia,
      });
      this.setPlano({
        ...data.plano
      });
      
      this.setDadosProposta({
        ...data,
      });

      if (!this.$route.params.guid) {
        this.$router.push({ name: "areaLogada.propostaAdesaoPME", params: { guidPME: this.$route.params.guidPME, guid: data.guid } });
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
