<template>
  <div>
    <v-toolbar dark dense :color="$store.getters.getColor('primary')" fixed>
      <v-btn icon @click="backPage">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="espacamento-linhas pt-1">
        <span>Resumo</span> <br />
        <span class="caption espacamento-linhas">Detalhes da Proposta</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="mr-2" v-show="showPendencias" @click="openDialog('DadosPendencias')">
        <v-badge color="red" overlap :content="pendenciasState.length">
          <v-icon color="white"> mdi-information-outline </v-icon>
        </v-badge>
      </v-btn>
      <v-btn text @click="recarregarDados">
        Atualizar
      </v-btn>
    </v-toolbar>

    <div id="scroll-pull">
      <div v-if="loadingDatas" class="d-flex justify-center mt-10 pa-10">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>

      <div v-else class="pl-md-5 pr-md-5">
        <v-row no-gutters class="border-down">
          <v-col md="6" class="text-center pa-2 mt-3 mb-3">
            <b>CNPJ</b><br />
            {{ proposta.cnpj | cnpjCpfMask }}
          </v-col>

          <v-col md="6" class="text-center pa-4 border-valorTotal">
            <b>Status</b><br />
            {{ retornaStatus }}
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6" sm="6" class="mt-4">
            <v-card outlined class="ml-4 mr-4" height="100%">
              <v-card-text class="espacamento-linhas16 pa-2">
                <v-row no-gutters :class="{ 'mb-9': showJustificativa }">
                  <v-col md="6" class="text-center pa-4">
                    <b>Data criação</b><br />
                    {{ proposta.dataCriacao | convertDatetimeFormat }}
                  </v-col>
                  <v-col md="6" class="text-center pa-4">
                    <b>Data alteração</b><br />
                    {{ proposta.dataAlteracao | convertDatetimeFormat }}
                  </v-col>
                </v-row>

                <v-row no-gutters :class="{ 'mb-9': showJustificativa }">
                  <v-col md="6" class="text-center pa-4">
                    <b>Corretora</b><br />
                    {{
                      (proposta.corretora && proposta.corretora.razaoSocial) ||
                      "--"
                    }}
                  </v-col>
                  <v-col md="6" class="text-center pa-2 mt-3 mb-3">
                    <b>Valor total</b><br />
                    <div>
                      {{ proposta.valorContrato | currency }}
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col md="6" class="text-center pa-4">
                    <b>Cliente</b><br />
                    {{ (proposta.razaoSocial && proposta.razaoSocial) || "--" }}
                  </v-col>
                  <v-col md="6" class="text-center pa-4">
                    <b>Celular</b><br />
                    {{ (celularTitular && celularTitular) || (celularResponsavelLegal && celularResponsavelLegal.numero)
                    || "--" }}
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="proposta.corretoraRepasse && proposta.corretoraRepasse.id">
                  <v-col md="6" class="text-center pa-4">
                    <b>Corretora de repasse</b><br />
                    {{ (proposta.corretoraRepasse && proposta.corretoraRepasse.nome) || "--" }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" sm="6" class="mt-4">
            <v-card outlined class="ml-4 mr-4" height="100%">
              <v-card-text class="espacamento-linhas16 pa-2">
                <v-row no-gutters>
                  <v-col md="6" class="text-center pa-4">
                    <b>Passo atual</b><br />
                    {{ proposta.etapa | etapaAdesaoPme }}
                    <v-btn icon class="mr-2" v-show="dadosAssinatura" @click="openDialog('DadosAssinaturaDps')">
                      <v-icon v-if="!verificaAssinaturaPendente" color="warning"> mdi-information-outline </v-icon>
                      <v-icon v-else color="success">mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                    <v-dialog v-model="dialogWarning" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="cursor-pointer" v-if="etapa === 'erro_validacao'" v-bind="attrs" v-on="on">
                          <v-icon color="warning">mdi-alert</v-icon>
                        </span>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Aviso
                        </v-card-title>
                        <v-card-text class="mt-5">
                          <p>Encontramos erros ou pendências para esta proposta.</p>
                          <p>Ajuste os itens apontados ou clique em "Revalidar" para confirmar se está tudo certo para
                            enviar ao cliente.</p>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" class="secundaryBtn--text" text @click="dialogWarning = false">
                            Entendi
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col md="6" class="left pa-1">
                    <span v-if="passoAtual === 'Implantada'" style="text-align: justify">A proposta ainda será analisada
                      pela operadora,
                      por favor, aguarde o status <b>Finalizado</b> para notificar seu cliente.</span>
                  </v-col>
                  <v-col md="6" class="text-center pa-4">
                    <v-btn v-if="botaoInfos.nome" :color="botaoInfos.color" :loading="botaoInfos.loading"
                      class="white--text" :disabled="botaoInfos.segundosParaHabilitar > 0 ||
                        botaoInfos.loading"
                      @click="botaoInfos.acao">
                      <b>{{
                        botaoInfos.segundosParaHabilitar > 0
                          ? `${botaoInfos.nome} (${botaoInfos.segundosParaHabilitar})`
                          : `${botaoInfos.nome}`
                      }}</b>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6" md="6" sm="6" class="text-center pa-4">
                    <b>Data da vigência</b><br />
                    {{ dataVigenciaReturn }}
                  </v-col>
                  <v-col cols="6" md="6" sm="6" class="text-center">
                    <v-btn v-if="mostrarBotaoAnexoCliente" :color="botaoEnviarAnexo.color"
                      :loading="botaoEnviarAnexo.loading"
                      :disabled="botaoEnviarAnexo.segundosParaHabilitar > 0 || botaoEnviarAnexo.loading"
                      class="white--text" @click="solicitarAnexoCliente()">
                      <b>{{ botaoEnviarAnexo.segundosParaHabilitar > 0
                        ? `${botaoEnviarAnexo.nome} (${botaoEnviarAnexo.segundosParaHabilitar})`
                        : `${botaoEnviarAnexo.nome}` }}</b>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="showJustificativa">
                  <v-col md="6" class="text-center pa-4">
                    <b>Justificativa</b><br />
                    {{ proposta.etapa.justificativa }}
                  </v-col>
                  <v-col md="6" class="text-center pa-4"> </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="
          proposta.etapa &&
          proposta.etapa === 'finalizada' &&
          proposta.titular.numeroCarteirinha
        ">
          <v-col cols="12" md="12" sm="12" class="mt-2">
            <v-card outlined class="ml-4 mr-4" height="100%">
              <v-card-text class="espacamento-linhas16 pa-2">
                <v-row no-gutters>
                  <v-col md="12" class="text-left pa-4">
                    <b>Número de Carteirinhas</b><br />
                    <div v-if="
                      proposta &&
                      proposta.titular &&
                      proposta.titular.nome &&
                      proposta.titular.numeroCarteirinha
                    " class="text-left mt-2">
                      <b>Titular </b><br />
                      <div class="mt-2">
                        <b>Nome: </b>{{ proposta.titular.nome || "--" }}<br />
                        <b>Carteirinha: </b>{{
                          (proposta.titular &&
                            proposta.titular.numeroCarteirinha) ||
                          "--"
                        }}
                      </div>
                    </div>
                    <div v-if="
                      proposta.dependentes &&
                      proposta.dependentes.length > 0 &&
                      proposta.dependentes.some(
                        (item) => item.numeroCarteirinha != null
                      )
                    " class="text-left mt-2">
                      <b>Dependentes </b><br />
                      <div v-for="(dep, index) in proposta.dependentes" :key="index">
                        <div class="mt-2" v-if="dep && dep.nome && dep.numeroCarteirinha">
                          <b>Nome: </b>{{ dep.nome || "--" }}<br />
                          <b>Carteirinha: </b>{{ dep.numeroCarteirinha || "--" }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <div v-if="showPendencias" class="ml-4">
          <v-row no-gutters class="pa-4 rowVerifPend" @click="openDialog('DadosPendencias')" style="cursor: pointer">
            <v-col cols="12" class="text-center">
              <v-icon color="amber">mdi-alert-circle</v-icon>
              <span class="hoverVerificarPendencias ml-1">Verificar pendências</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>

        <v-row no-gutters class="mr-4">
          <v-col cols="12" md="4" sm="6" class="mt-4" v-show="showAdendo">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('DadosAdendo')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-center mt-1">
                    <v-icon size="45">mdi-square-edit-outline</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Adendo</span><br />
                    <span class="caption mt-0 espacamento-linhas">Relação de dados alterados</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn class="subtitle-1 pt-1 mt-1 secundaryBtn--text" fab small color="primary" elevation="0">
                      {{ adendoState.length }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" sm="6" class="mt-4" v-show="showAnexosAlterados">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('AnexosAlterados')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-center mt-1">
                    <v-icon size="45">mdi-square-edit-outline</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Anexos alterados</span><br />
                    <span class="caption mt-0 espacamento-linhas">Relação de anexos alterados</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn class="subtitle-1 pt-1 mt-1 secundaryBtn--text" fab small color="primary" elevation="0">
                      {{ proposta.qtdAnexosAlterados }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" sm="6" class="mt-4">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('DadosEmpresa')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-lg-center mt-1">
                    <v-icon size="45">mdi-account</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Dados da Empresa</span><br />
                    <span class="caption mt-0 espacamento-linhas">Informações pessoais e endereços</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn icon class="mt-1">
                      <v-icon size="45">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" sm="6" class="mt-4">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('DadosVigencia')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-lg-center mt-1">
                    <v-icon size="45">mdi-calendar-month</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Dados Vigência</span><br />
                    <span class="caption mt-0 espacamento-linhas">Informações sobre a vigência selecionada</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn icon class="mt-1">
                      <v-icon size="45">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" sm="6" class="mt-4">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('DadosFinanceiros')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-lg-center mt-1">
                    <v-icon size="45">mdi-cash</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Dados Financeiros</span><br />
                    <span class="caption mt-0 espacamento-linhas">Informações financeiras</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn icon class="mt-1">
                      <v-icon size="45">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="
            propostaState &&
            propostaState.titular &&
            propostaState.titular.entidade &&
            ($store.getters.getFiliacaoEntidadesPermitidas == null ||
              $store.getters.getFiliacaoEntidadesPermitidas.includes(
                propostaState.titular.entidade.toUpperCase()
              ))
          " cols="12" md="4" sm="6" class="mt-4">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('DadosFiliacao')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-lg-center mt-1">
                    <v-icon size="45">mdi-content-paste</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Ficha de Filiação</span><br />
                    <span class="caption espacamento-linhas">Informações sobre a ficha de filiação</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn icon class="mt-1">
                      <v-icon size="45">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="
            propostaState &&
            propostaState.possuiPortabilidade &&
            propostaState.portabilidadePorBeneficiario
          " cols="12" md="4" sm="6" class="mt-4">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('DadosPortabilidade')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-lg-center mt-1">
                    <v-icon size="45">mdi-account-switch</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Dados Portabilidade</span><br />
                    <span class="caption espacamento-linhas">Informações de portabilidade dos beneficiários</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn icon class="mt-1">
                      <v-icon size="45">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" sm="6" class="mt-4">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('Anexos')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-lg-center mt-1">
                    <v-icon size="45">mdi-paperclip</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Anexos</span><br />
                    <span class="caption espacamento-linhas">Relação de documentos</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn icon class="mt-1">
                      <v-icon size="45">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" sm="6" class="mt-4">
            <v-card outlined class="ml-4" height="100%" @click="openDialog('Contrato')">
              <v-card-text class="espacamento-linhas pa-2">
                <v-row no-gutters>
                  <v-col cols="2" md="3" class="text-lg-center mt-1">
                    <v-icon size="45">mdi-file-document-multiple</v-icon>
                  </v-col>
                  <v-col cols="8" md="7" class="text-left">
                    <span class="subtitle-2 mb-0">Contrato</span><br />
                    <span class="caption espacamento-linhas">Visualizar contrato</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-btn icon class="mt-1">
                      <v-icon size="45">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div v-if="proposta.status">
          <v-row v-if="
            proposta.status.toUpperCase() === 'EM_DIGITACAO' ||
            proposta.status.toUpperCase() === 'DEVOLVIDA'
          " justify="center" class="mt-5">
            <v-col cols="12" md="4" class="text-center">
              <v-btn color="red" depressed elevation="2" class="white--text elevation-0 px-10"
                @click="openCancelModal(true)">Cancelar Proposta</v-btn>
            </v-col>
          </v-row>
          <!-- CLONAR PROPOSTA -->
          <v-row v-if="proposta.status.toUpperCase() === 'CANCELADA'" justify="center" class="mt-2">
            <v-col cols="12" md="4" class="text-center">
              <v-btn color="primary" depressed elevation="2" class="secundaryBtn--text elevation-0 px-10"
                @click="propostaClonar.dialog = true">Clonar Proposta</v-btn>
            </v-col>
          </v-row>
          <modal-confirmation :show="propostaClonar.dialog" name="clonar" :loading="propostaClonar.loading"
            :title="propostaClonar.title" :text="propostaClonar.text" @close="propostaClonar.dialog = false"
            @confirm="clonar" />
          <!-- ASSOCIAR-->
          <modal-confirmation :show="propostaAssociar.dialog" name="associar" :loading="propostaAssociar.loading"
            :title="propostaAssociar.title" :text="propostaAssociar.text" @close="propostaAssociar.dialog = false"
            @confirm="associar" />
        </div>
      </div>
    </div>
    <v-dialog v-model="propostaCancelar.dialog" max-width="400px">
      <v-card>
        <v-card-title>
          <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">
            Cancelar Proposta
          </h2>
        </v-card-title>
        <validation-observer ref="formCancelarProposta" tag="form" @submit.prevent="cancelarProposta">
          <v-card-text>
            <v-radio-group v-model="propostaCancelar.justificativa">
              <div v-for="(just, index) in justificativas" :key="`dado-${index}`">
                <v-radio :data-test-id="`dado-${index}`" :label="just.valor"
                  :value="just.valor"></v-radio>
              </div>
            </v-radio-group>
            <base-text-field v-if="propostaCancelar.justificativa === 'Outros'" rules="required" id="justificativa"
              name="justificativa" label="Justificativa" minlength="5" v-model="justificativaDissertativa" clearable
              outlined></base-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-1" color="red darken-1" text @click="openCancelModal(false)">
              cancelar
            </v-btn>
            <v-btn color="primary" text class="secundaryBtn--text" type="submit" :loading="propostaCancelar.loading">
              confirmar
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>

    <v-dialog :retain-focus="false" no-click-animation v-model="dialog" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card tile>
        <component ref="component" :is="pages[dadosSelected]" @closedialog="closeDialog"
          @recarregardocumentos="recarregarDocumentos" @showmsgeditar="showMensagemEditarProposta"
          :validarEdicao="validarEdicaoProposta" :validarEnvioAssAdendo="validarEnvioAssAdendo" :showBotao="showBotao"
          :anexarArquivos="anexarArquivos" :excluirArquivos="excluirArquivos" :etapa="etapa" :data="dadosValidacao"
          :origem="'resumo'" :matchConversao="vinculoProposta.matchConversao.valores"></component>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAnexo" max-width="450" eager>
      <v-card class="pa-0">
        <v-sheet color="primary pa-2" style="border-bottom-right-radius: 0; border-bottom-left-radius: 0">
          <h2 class="white--text title text-center">Aviso</h2>
        </v-sheet>
        <v-sheet class="pa-4 pt-6 pb-0">
          <p class="subtitle-2 text-center mb-3">A inclusão de anexos após a assinatura do cliente acarretará na
            assinatura
            de um adendo concordando com a nova documentação inserida no contrato.</p>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet color="pa-2" class="d-flex">
          <v-btn :data-test-id="'voltar-para-anexo'" text color="primary" class="secundaryBtn--text"
            @click="onClickIrParaAnexo">
            <span class="font-weight-bold text-capitalize subtitle-1 grey--text">
              {{ $vuetify.breakpoint.xs ? 'Anexo' : 'Ir para anexo' }}
            </span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :data-test-id="'enviar-para-assinatura'" text color="primary" class="secundaryBtn--text" type="submit"
            @click="onClickEnviarParaAssinatura(botaoInfos.nome)">
            <span class="font-weight-bold text-capitalize subtitle-1 blue--text">Enviar para assinatura</span>
          </v-btn>
        </v-sheet>
      </v-card>
    </v-dialog>

    <!-- COMENTADO POR IMPEDIR A TRANSMISSÃO, AINDA NÃO TEM VINCULAÇÃO DE LEADS PARA O PME
    <vinculo-proposta ref="vinculoProposta" :leads="vinculoProposta.leads" :origensVenda="vinculoProposta.origensVenda"
      @recarregardados="recarregarDados" @transmitir="transmitir" /> -->
  </div>
</template>

<script>
import axios from 'axios';
import filters from "@/filters";
import { mapActions, mapState } from "vuex";
import { Etapas } from "@/utils/etapas";
import propostaPMEService from '@/services/propostaPMEService';
import empresaService from '@/services/empresaService';
import leadService from "@/services/leadService";
import regrasService from "@/services/regrasService";
import usuarioService from '@/services/usuarioService';
import documentoService from "@/services/documentoService";
import corretorasService from "@/services/corretoraService";
import ValidacaoDadosDialog from '@/views/AreaLogada/CriarProposta/ValidacaoDados/index.vue';
import BaseTextField from "@/components/BaseTextField.vue";
import ModalConfirmation from "@/components/ModalConfirmation.vue";
import DadosEmpresa from "./DadosEmpresa/index.vue";
import DadosDependentes from "./DadosDependentes/index.vue";
import DadosVigencia from "./DadosVigencia/index.vue";
import DadosPortabilidade from "./DadosPortabilidade/index.vue";
import DadosProfissionais from "./DadosProfissionais/index.vue";
import DadosFiliacao from "./DadosFiliacao/index.vue";
import DadosFinanceiros from "./DadosFinanceiros/index.vue";
import Anexos from "./Anexos/index.vue";
import Contrato from "./Contrato/index.vue";
import DadosPendencias from "./DadosPendencias/index.vue";
import DadosAssinaturaDps from "./DadosAssinaturaDps/index.vue";
import DadosAdendo from "./DadosAdendo/index.vue";
import AnexosAlterados from "./AnexosAlterados/index.vue";
import ValidacaoDados from "./ValidacaoDados/index.vue";
import VinculoProposta from "./VinculoProposta/index.vue";
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import propostaService from '@/services/propostaService';

export default {
  data() {
    return {
      botaoEnviarAnexo: {
        nome: "Solicitar anexo",
        segundosParaHabilitar: 0,
        color: 'secundary',
        loading: false,
        acao: () => { },
      },
      justificativaDissertativa: "",
      botaoInfos: {
        nome: "",
        segundosParaHabilitar: 0,
        color: 'secundary',
        loading: false,
        acao: () => { },
      },
      etapa: {},
      proposta: {},
      assinaturas: [],
      verificaAssinaturaPendente: null,
      loadingDatas: false,
      justificativas: [
        { valor: "Cliente desistiu da adesão" },
        { valor: "Perda de vigência" },
        { valor: "Alteração de dados do plano" },
        { valor: "Alteração de dados do cliente" },
        { valor: "Problemas na assinatura do cliente" },
        { valor: "Erro na proposta" },
        { valor: "Outros" },
      ],
      propostaClonar: {
        dialog: false,
        loading: false,
        text: "Deseja copiar os dados desta proposta para uma nova proposta?",
        title: "Clonar Proposta",
      },
      propostaAssociar: {
        dialog: false,
        loading: false,
        text: "Deseja associar a proposta para este usuário?",
        title: "Associar Proposta",
      },
      propostaCancelar: {
        dialog: false,
        justificativa: "",
        loading: false,
      },
      dadosSelected: "DadosEmpresa",
      dialogWarning: false,
      pages: {
        DadosEmpresa,
        DadosDependentes,
        DadosVigencia,
        DadosPortabilidade,
        DadosProfissionais,
        DadosFiliacao,
        DadosFinanceiros,
        Anexos,
        DadosPendencias,
        DadosAssinaturaDps,
        DadosAdendo,
        AnexosAlterados,
        ValidacaoDados,
        Contrato,
        ValidacaoDadosDialog,
      },
      dialog: false,
      dialogAnexo: false,
      beneficiarios: [],
      documentos: [],
      countNavegacao: -1,
      dadosValidacao: {},
      vinculoProposta: {
        leads: [],
        origensVenda: [],
        matchConversao: {
          proprioCorretor: false,
          valores: [],
        },
        perguntar: false,
      },
    };
  },
  components: {
    BaseTextField,
    ModalConfirmation,
    VinculoProposta,
  },
  async mounted() {
    await this.recarregarDados();
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
      pendenciasState: (state) => state.PME.pendencias,
      titularState: (state) => state.cadastroProposta.titular,
      dependentesState: (state) => state.cadastroProposta.dependentes,
      adendoState: (state) => state.PME.adendo,
      user: (state) => state.user.profile,
    }),
    corRelogio() {
      let corRelogio = 'green';
      if (this.proposta.vigencia.tempoPrioridade === 'MEDIA') {
        corRelogio = 'yellow';
      }
      if (this.proposta.vigencia.tempoPrioridade === 'ALTA') {
        corRelogio = 'red';
      }
      return corRelogio;
    },
    celularTitular() {
      if (
        !(
          this.proposta
          && this.proposta.responsavel
          && this.proposta.responsavel.telefone
        )
      ) { return ""; }
      return this.proposta.responsavel.telefone
    },
    passoAtual() {
      if (this.proposta.etapa) {
        if (this.proposta.etapa.id === "em_analise") {
          if (this.proposta.etapa.contador > 0) {
            return `${this.proposta.etapa.contador}ª transmissão`;
          }
          return "Transmitido";
        }
        return this.proposta.etapa.subtitulo;
      }
      return "Não definido";
    },
    celularResponsavelLegal() {
      if (!(this.proposta && this.proposta.responsavelLegal && this.proposta.responsavelLegal.telefones)) { return ""; }
      return this.proposta.responsavelLegal.telefones.find((r) => r.tipo === "CELULAR" && r.preferencial === true);
    },
    enderecoResidencial() {
      if (
        !(
          this.proposta
          && this.proposta.titular
          && this.proposta.titular.enderecos
        )
      ) { return ""; }
      return this.proposta.titular.enderecos.find(
        ({ tipo }) => tipo === "ENDERECO_RESIDENCIAL",
      );
    },
    telefoneOpcional() {
      if (
        !(
          this.proposta
          && this.proposta.titular
          && this.proposta.titular.telefones
        )
      ) { return ""; }
      return this.proposta.titular.telefones.find(
        ({ preferencial }) => !preferencial,
      );
    },
    showPendencias() {
      return this.pendenciasState && this.pendenciasState.length;
    },
    dadosAssinatura() {
      return this.propostaState && this.proposta?.etapa?.toLowerCase() === "aguardando_assinatura";
    },
    showAdendo() {
      return (
        this.adendoState
        && this.adendoState.length
        && this.proposta.status
        && this.proposta.status.toUpperCase() === "DEVOLVIDA"
      );
    },
    showAnexosAlterados() {
      return (
        this.proposta.status
        && this.proposta.status.toUpperCase() === "EM_DIGITACAO"
        && this.proposta.etapa
        && (this.proposta.etapa === 'aguardando_envio_anexos' || this.proposta.etapa === 'aguardando_aceite_anexos')
      );
    },
    showBotao() {
      if (
        this.proposta?.etapa?.toLowerCase() === "aguardando_assinatura"
        && this.proposta.permiteEditar === false
      ) {
        return false;
      }

      return (
        this.botaoInfos.nome !== ""
        && this.botaoInfos.nome.toUpperCase() !== "ASSOCIAR"
        && this.proposta.etapa.toLowerCase() !== "contrato_assinado"
        && this.proposta.etapa.toLowerCase() !== "aguardando_assinatura_adendo"
        && this.proposta.etapa.toLowerCase() !== "adendo_assinado"
      );
    },
    mostrarBotaoAnexoCliente() {
      return (this.proposta.clienteAnexaDocumentos && this?.proposta?.etapa?.id.toLowerCase() === "contrato_assinado"
        && this?.proposta?.status.toUpperCase() === "EM_DIGITACAO");
    },
    showJustificativa() {
      return (
        this.proposta
        && this.proposta.etapa
        && this.proposta.etapa.justificativa
      );
    },
    anexarArquivos() {
      if (this.proposta.status) {
        return (
          this.proposta.status.toUpperCase() === "EM_DIGITACAO"
          || this.proposta.status.toUpperCase() === "DEVOLVIDA"
          || this.proposta.status.toUpperCase() === "FILA_TRANSMISSAO"
        );
      }
      return false;
    },
    excluirArquivos() {
      if (this.proposta.status) {
        return this.proposta.status.toUpperCase() === "EM_DIGITACAO";
      }
      return false;
    },
    dataVigenciaReturn() {
      if (this.proposta.vigencia) {
        return filters.convertDateOutput(this.proposta.vigencia);
      }
      return "--";
    },
    retornaStatus() {
      if(this.proposta.etapa === "clonada" || this.proposta.etapa === "clonada_sem_supervidor")
        return "Em digitação"

      return filters.etapaAdesaoPme(this.proposta.etapa)
    },
  },
  beforeDestroy() {
    window.PullToRefresh = null;
    const element = document.getElementById('tag-pull-to-refresh');
    element.parentNode.removeChild(element);
  },
  beforeCreate() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "pulltorefresh/index.js";
    s.id = "tag-pull-to-refresh";
    document.body.appendChild(s);
  },
  created() {
    const vm = this;
    setTimeout(() => {
      window.PullToRefresh.init({
        mainElement: '#scroll-pull',
        onRefresh() {
          // SE existir a tag script de pull refresh, recarrega dados
          const element = document.getElementById('tag-pull-to-refresh');
          if (element) { vm.recarregarDados(); }
        },
      });
    }, 800);
  },
  watch: {
    propostaCancelar: {
      handler(val) {
        if (val.justificativa !== "Outros") {
          this.justificativaDissertativa = ""
        }

      },
      deep: true
    },
  },
  methods: {
    ...mapActions({
      setPendencias: 'PME/setPendencias',
      setGuid: 'PME/setGUID',
      setID: 'PME/setID',
      setEmpresa: 'PME/setEmpresa',
      setTitularPME: 'PME/setTitularPME',
      setDadosDependentes: 'PME/setDadosDependentes',
      setCorretora: 'PME/setCorretora',
      setOperadora: 'PME/setOperadora',
      setPlano: 'PME/setPlano',
      setVigencia: 'PME/setVigencia',
      resetDadosPME: 'PME/resetDadosPME',
      resetDadosProposta: 'cadastroProposta/resetDadosProposta',
      setAdendo: 'PME/setAdendo',
    }),
    getLocation() {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const { coords } = pos;
            if (coords && coords.latitude && coords.longitude) {
              resolve(JSON.stringify({ latitude: coords.latitude, longitude: coords.longitude }));
            }
          },
          (err) => {
            const { message } = err;
            resolve(JSON.stringify({ error: message }));
          }
        );
      });
    },
    async getLocationApp() {
      try {
        const { coords } = await Geolocation.getCurrentPosition();
        if (coords && coords.latitude && coords.longitude) {
          return JSON.stringify({ latitude: coords.latitude, longitude: coords.longitude });
        }
      } catch (error) {
        const { message } = error;
        return JSON.stringify({ error: message });
      }
    },
    resetProposta() {
      this.resetDadosPME();
      this.setEmpresa({
        titular: {},
        dependentes: [],
        corretora: {},
        plano: {},
        dataVigencia: "",
        dadosProfissionais: {},
        dadosComplementaresFiliacao: {},
        pagamento: {},
        produtosOpcionais: [],
        objEtapas: {},
      });
    },
    openCancelModal(dialog) {
      this.propostaCancelar.dialog = dialog
      this.justificativaDissertativa = ""
      this.propostaCancelar.justificativa = ""
    },
    async getAssinaturas() {
      const { guid: guidProposta } = this.$route.params;
      try {
        const assinaturas = await propostaPMEService.getAssinaturas({ guidProposta });
        this.assinaturas = assinaturas.data.data;
        this.verificaAssinaturaPendente = this.assinaturas ? this.assinaturas.reduce((acc, item) => acc && (item.assinado || false), true) : true;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao carregar assinaturas, tente novamente mais tarde",
        });
      }
    },
    backPage() {
      const { guid } = this.$route.params;
      this.$router.push({ name: 'areaLogada.resumoPropostaPME', params: { guid } });
    },
    async solicitarAnexoCliente() {
      this.botaoEnviarAnexo.loading = true;
      try {
        const { guid: guidProposta } = this.$route.params;
        const enviaSolicitacao = await propostaPMEService.enviaSolicitacaoAnexo({ guidProposta });
        this.desabilitaPor(this.botaoEnviarAnexo, 40);
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message,
        });
      } finally {
        this.botaoEnviarAnexo.loading = false;
      }
    },
    async recarregarDados() {
      this.loadingDatas = true;
      try {
        this.resetProposta();
        await this.carregarDadosProposta();
        await this.carregarAdendo();
        await this.carregarPendencias();
        this.carregarEtapa();
        this.getInfosBotao();
        this.setProps();
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message,
        });
      } finally {
        this.loadingDatas = false;
      }
    },
    openDialog(componentRef) {
      this.dadosSelected = componentRef;
      this.dialog = true;
    },
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.objEtapas = objEtapas;

      this.etapa = objEtapas.getEtapaAtual(this.proposta.etapa || "");
    },
    async carregarAdendo() {
      if (this.proposta && this.proposta.status === "DEVOLVIDA") {
        const { guid: guidProposta } = this.$route.params;
        const { data } = await propostaPMEService.getAdendo({ guidProposta });
        if (!data) {
          throw new Error();
        }
        this.setAdendo(data);
      }
    },
    async carregarPendencias() {
      const { guid: guidProposta } = this.$route.params;
      const { data } = await propostaPMEService.getPendencias({ guidProposta });
      if (!data) {
        throw new Error();
      }
      this.setPendencias(data.pendencia);
    },
    async buscarDadosProposta() {
      const { guid } = this.$route.params;
      const data = await empresaService.getByGuid(guid);

      if (!data) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Proposta não encontrada",
        });
      }

      const proposta = data;

      return proposta;
    },
    async buscarAlteracaoAnexos() {
      const { guid: guidProposta } = this.$route.params;
      const data = await propostaPMEService.buscarAlteracaoAnexos({ guidProposta });
      if (!data) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Proposta não encontrada",
        });
      }
      return data;
    },
    async carregarDadosProposta() {
      const proposta = await this.buscarDadosProposta();

      this.proposta = proposta;
    },
    async validarEdicaoProposta() {
      const aguardandoAssinaturaEtapa = this.objEtapas.getEtapaAtual("aguardando_assinatura");
      if (!this.etapa || this.etapa.ordem < aguardandoAssinaturaEtapa.ordem) return true;

      const proposta = await this.buscarDadosProposta();

      const permiteEditar = !(proposta && proposta.permiteEditar === false);
      const emDigitacao = ((proposta && proposta.status) || '').toUpperCase() === 'EM_DIGITACAO';

      return emDigitacao ? permiteEditar : true;
    },
    async validarEnvioAssAdendo() {
      const proposta = await this.buscarDadosProposta();
      return proposta?.etapa?.toLowerCase() !== 'adendo_assinado';
    },
    async showMensagemEditarProposta() {
      this.$root.$snackBar.open({
        color: "error",
        message: "Não é permitido editar a proposta",
      });
    },
    verificarSetarDadosProfissionais() {
      if (this.proposta.titular && this.proposta.titular.dadosProfissionais) {
        const {
          cnpj,
          razaoSocial,
          nomeFantasia,
          ramoAtividade,
          sindicato,
        } = this.proposta.titular.dadosProfissionais;
        return (
          cnpj || razaoSocial || nomeFantasia || ramoAtividade || sindicato
        );
      }
      return false;
    },
    verificarSetarDadosReembolso() {
      if (this.proposta.dadosParaReembolso) {
        const {
          agencia,
          banco,
          codBanco,
          contaCorrente,
          ordemPagamento,
        } = this.proposta.dadosParaReembolso;
        return agencia || banco || codBanco || contaCorrente || ordemPagamento;
      }
      return false;
    },
    verificarSetarPlano() {
      return (
        this.proposta
        && this.proposta.plano
        && Object.keys(this.proposta.plano).length
      );
    },
    setProps() {
      const telOpc = this.telefoneOpcional;
      this.setTitularPME({
        ...this.proposta.titular,
        ...{
          telefoneCelular: this.celularTitular && this.celularTitular.numero,
        },
        ...{ tipoTelefoneOpcional: telOpc && telOpc.tipo },
        ...{ telefoneOpcional: telOpc && telOpc.numero },
        ...{ cep: this.enderecoResidencial && this.enderecoResidencial.cep },
        enderecos: this.tratarEnderecos(),
      });

      if (this.proposta.dependentes) {
        const deps = this.proposta.dependentes.map(
          ({ parentesco, ...resto }) => ({
            ...resto,
            parentesco: parentesco && parentesco.nome,
          }),
        );
      }
      this.setCorretora({
        corretora: {
          ...this.proposta.corretora,
          guidSupervisor:
            this.proposta.corretora && this.proposta.corretora.supervisor
              ? this.proposta.corretora.supervisor.guid
              : null,
        },
      });
      if (this.verificarSetarPlano()) {
        this.setOperadora(this.proposta.plano.operadora);
        this.setPlano({ ...this.proposta.plano });
        this.setProdutosOpcionais([...this.proposta.produtosOpcionais]);
      }
      this.setVigencia({
        dataVigencia: filters.convertDateInput(
          (this.proposta.vigencia && this.proposta.vigencia.dataVigencia) || "",
        ),
        vigencia: this.proposta.vigencia,
      });

      this.setGuid(this.proposta.guid)
      this.setID(this.proposta.id)
      this.setEmpresa({
        enderecoEmpresa: this.proposta.endereco,
        pagamento: { 
          formaPagamento: this.proposta.formaPagamento,
          porcentagemTaxaAngariacao: this.proposta.porcentagemTaxaAngariacao,
          banco: this.proposta.banco,
          agencia: this.proposta.agencia,
          contaCorrente: this.proposta.contaCorrente
         },
        razaoSocial: this.proposta.razaoSocial,
        representanteEmpresa: this.proposta.responsavel,
        etapa: this.proposta.etapa,
        status: this.proposta.status,
        dadosEmpresa: {
          id: this.proposta.id,
          cnpj: this.proposta.cnpj,
          dataAbertura: this.proposta.dataAbertura,
          razaoSocial: this.proposta.razaoSocial,
          inscricaoMunicipal: this.proposta.inscricaoMunicipal,
          cnae: this.proposta.cnae,
          nomeFantasia: this.proposta.nomeFantasia
        }
      })
      this.setCorretora({ corretora: this.proposta.corretora })
      this.setOperadora({ nome: this.proposta.operadora })
      this.setVigencia({ tempoParaTransmissao: this.proposta.tempoParaTransmissao, vigencia: this.proposta.vigencia, dataFechamentoAceitacao: this.proposta.dataFechamentoAceitacao, dataFechamentoReapresentacao: this.proposta.dataFechamentoReapresentacao })
      this.resetDadosProposta()
    },
    async cancelarProposta() {
      const isValid = await this.$refs.formCancelarProposta.validate();
      if (!isValid) return;
      this.propostaCancelar.loading = true;
      try {
        const id = this.proposta.id;
        await propostaPMEService.cancelarProposta({
          id,
          justificativa: this.propostaCancelar.justificativa === "Outros" ? this.justificativaDissertativa : this.propostaCancelar.justificativa
        });
        await this.proposta.propostas.forEach(async p => {

          await propostaService.cancelarProposta({
            guidProposta: p.guid,
            justificativa: this.propostaCancelar.justificativa === "Outros" ? this.justificativaDissertativa : this.propostaCancelar.justificativa
          })

          })
        this.$root.$snackBar.open({
          color: "success",
          message: "Proposta Cancelada",
        });
        this.recarregarDados();
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao reenviar, tente novamente mais tarde",
        });
      } finally {
        this.propostaCancelar.loading = false;
        this.propostaCancelar.dialog = false;
      }
    },
    async clonar() {
      this.propostaClonar.loading = true;
      try {
        const { guid: guidProposta } = this.proposta;
        const corretoras = await corretorasService.getCorretorasPorCpf(this.user);

        const corretora = corretoras.find((item) => item.cpfCnpj === this.proposta.corretora.cpfCnpj);
        const supervisorProposta = this.proposta.corretora.supervisor;
        let naoTemSupervisor = false;
        if (!corretora) {
          throw new Error("Não é possível clonar, a corretora não está mais associada a este corretor");
        } else if (supervisorProposta !== null && supervisorProposta !== undefined) {

          const { status } = await usuarioService.retornarStatusSolicitacao(supervisorProposta.guid);
          if (!corretora.supervisores.find((item) => item.guid === supervisorProposta.guid)) {
            naoTemSupervisor = true;
          } else if (status === "REPROVADO") {
            naoTemSupervisor = true;
          }
        }

        await propostaPMEService.clonarProposta({ guidProposta, naoTemSupervisor });
        this.mostrarMensagemSucesso("Proposta Clonada");
        this.$router.push({ name: "areaLogada.propostasPME" });
      } catch (error) {
        console.log(error)
        this.$root.$snackBar.open({
          color: "error",
          message:
            error.message || "Erro ao clonar, tente novamente mais tarde",
        });
      } finally {
        this.propostaClonar.loading = false;
      }
    },
    async associar() {
      this.propostaAssociar.loading = true;
      try {
        const { id: idProposta } = this.proposta;
        const { id: idUsuario } = this.user;
        await propostaPMEService.associarProposta({
          idProposta,
          tipoUsuario: "CORRETOR",
          idUsuario,
        });

        this.mostrarMensagemSucesso("Proposta Associada");
        this.recarregarDados();
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao associar, tente novamente mais tarde",
        });
      } finally {
        this.propostaAssociar.loading = false;
        this.propostaAssociar.dialog = false;
      }
    },
    mostrarMensagemSucesso(msg) {
      this.$root.$snackBar.open({
        color: "success",
        message: msg,
      });
    },
    desabilitaPor(botao, segundos) {
      botao.segundosParaHabilitar = segundos;
      setTimeout(() => {
        botao.segundosParaHabilitar -= 1;
        if (botao.segundosParaHabilitar > 0) {
          this.desabilitaPor(botao, segundos - 1);
        }
      }, 1000);
    },
    getInfosBotao() {
      if (!this.proposta.etapa) return;
      const etapa = this.proposta.etapa;
      this.botaoInfos.color = "primary";
      const continuarEtapas = ["selecao_operadora", "selecao_vigencia", "dados_financeiros", "selecao_documentos", "inclusao_beneficiarios", "clonada", "clonada_sem_supervisor"];
      if (continuarEtapas.includes(this.proposta.etapa)) {
        if (this.proposta.etapa === 'inclusao_beneficiarios' && this.proposta.propostas.length) return;
        this.botaoInfos.nome = "Continuar";
        this.botaoInfos.acao = () => {
          this.btnContinuar();
        };
        return
      } else if (
        etapa === "aguardando_assinatura"
        || etapa === "assinatura_recusada" || (!this.verificaAssinaturaPendente && etapa === "aguardando_assinatura")
      ) {
        this.botaoInfos.nome = "Reenviar";

        this.botaoInfos.acao = async () => {
          if (this.botaoInfos.segundosParaHabilitar <= 0) {
            this.dialogAnexo = true;
          }
        };
      } else if (etapa === "preenchido") {
        this.botaoInfos.nome = "Enviar";
        this.botaoInfos.acao = async () => {
          if (this.botaoInfos.segundosParaHabilitar <= 0) {
            this.dialogAnexo = true;
          }
        };
      } else if (etapa === "erro_validacao") {
        this.botaoInfos.nome = "Revalidar";
        this.botaoInfos.color = "warning";
        this.botaoInfos.acao = async () => {
          try {
            this.botaoInfos.loading = true;
            const { guid: guidProposta } = this.proposta;
            const { data } = await propostaPMEService.validarEnvioAcessoCliente({ guidProposta });
            if (data && data.prontoParaEnvio) {
              await propostaPMEService.atualizarEtapa({ guidProposta, etapa: 'preenchido' });
              await this.recarregarDados();
              this.$root.$snackBar.open({
                color: "success",
                message: "Pronto para enviar ao cliente!",
              });
              this.botaoInfos.loading = false;
            } else {
              this.dadosValidacao = data;
              this.openDialog("ValidacaoDadosDialog");
              this.botaoInfos.loading = false;
            }
          } catch (error) {
            this.botaoInfos.loading = false;
            this.$root.$snackBar.open({
              color: "error",
              message: error.message,
            });
          }
        };
      } else if (
        etapa === "pronta_transmissao"
        || etapa === "contrato_assinado"
        || etapa === 'aguardando_envio_anexos'
        || etapa === 'aguardando_aceite_anexos'
        || etapa === 'anexos_recusados'
        || etapa === 'anexos_aceitos'
        || (this.proposta.status === "FILA_TRANSMISSAO"
          && etapa === "erro_transmissao")
      ) {
        //this.verificaVinculoProposta(); //COMENTADO POR IMPEDIR A TRANSMISSÃO, AINDA NÃO TEM VINCULAÇÃO DE LEADS NO PME
        this.botaoInfos.nome = "Transmitir";
        this.botaoInfos.acao = () => {
          this.verificaTransmissao();
        };
      } else if (
        this.proposta.usuarioTratando
        && this.proposta.usuarioTratando.nome === this.user.nome
        && (this.proposta.status === "DEVOLVIDA"
          || (this.proposta.status === "FILA_TRANSMISSAO"
            && etapa === "erro_transmissao"))
      ) {
        this.botaoInfos.nome = "Retransmitir";
        this.botaoInfos.acao = () => {
          this.retransmitir();
        };
      } else if (
        !this.proposta.usuarioTratando
        && this.proposta.status === "DEVOLVIDA"
      ) {
        this.botaoInfos.nome = "Associar";
        this.botaoInfos.acao = () => {
          this.propostaAssociar.dialog = true;
        };
      } else {
        this.botaoInfos.nome = "";
        this.botaoInfos.acao = () => { };
      }
    },
    async verificaVinculoProposta() {
      this.$set(this.vinculoProposta, 'loaded', false);
      try {
        await Promise.all([
          this.consultaVinculoPropostaLeads(),
          this.consultaMatchConversao(),
          this.consultaOrigensVenda(),
        ]);
        const { leads, matchConversao } = this.vinculoProposta;
        this.vinculoProposta.perguntar = leads.length
          && !matchConversao.proprioCorretor
          && !(this.proposta.leads || []).length
          && !this.proposta.origemVenda;
        this.$set(this.vinculoProposta, 'loaded', true);
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao validar transmissão",
        });
      }
    },
    async consultaOrigensVenda() {
      let origensVenda = await regrasService.listarOrigensVenda();
      origensVenda = origensVenda.filter((item) => item.ativo);
      this.vinculoProposta.origensVenda = origensVenda;
    },
    async consultaVinculoPropostaLeads() {
      const leads = await leadService.listarLeadsVinculoProposta();
      this.vinculoProposta.leads = leads;
    },
    async consultaMatchConversao() {
      const matchConversao = await leadService.getMatchConversao(this.proposta.guid);
      this.vinculoProposta.matchConversao.proprioCorretor = matchConversao.proprioCorretor || false;
      this.vinculoProposta.matchConversao.valores = matchConversao.matchConversao || [];
    },
    tratarEnderecos() {
      const enderecos = [];
      if (
        this.proposta
        && this.proposta.titular
        && this.proposta.titular.enderecos
      ) {
        this.proposta.titular.enderecos.forEach((e) => {
          const item = enderecos.find((ae) => e.cep === ae.cep);
          if (!item) {
            enderecos.push({
              bairro: e.bairro,
              cep: e.cep,
              cidade: e.cidade,
              complemento: e.complemento,
              estado: e.estado,
              logradouro: e.logradouro,
              numero: e.numero,
              tipo: [e.tipo],
            });
          } else {
            item.tipo.push(e.tipo);
          }
        });
      }
      return enderecos;
    },
    btnContinuar() {
      // let isEditingVigencia;
      // if (localStorage.getItem('etapaBeforeReset')) {
      //   isEditingVigencia = true;
      // }
      // this.setBlnContinuar({ flag: true, page: 1, isEditingVigencia });
      this.$router.push({ name: "areaLogada.PME", params: { guid: this.$route.params.guid } });	
    },
    async salvarGeolocalizacao(guidProposta) {
      const location = Capacitor.isNativePlatform() ? await this.getLocationApp() : await this.getLocation();
      if (location) {
        const geolocalizacao = JSON.parse(location);
        await propostaPMEService.addOrEdit(guidProposta, { geolocalizacaoCorretor: geolocalizacao });
      }
    },
    async getIp() {
      let ip = '';
      try {
        const { data } = await axios.get('https://api.ipify.org?format=json');
        ip = data.ip;
      } catch (error) {
        ip = '';
      }
      return ip;
    },
    transmitir() {
      this.openDialog("ValidacaoDados");
    },
    async verificaTransmissao() {
      try {
        const listaCorretoras = await corretorasService.getCorretoraPorCpfCnpj({cpfCnpj: this.proposta.corretora.cpfCnpj})
        if (!(listaCorretoras && listaCorretoras.length > 0)) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Erro ao transmitir, não foi possível encontrar a corretora vinculada a proposta",
          });
          return
        }
        const corretoraProposta = listaCorretoras[0];
        const verificaCorretoraMesmoTipoQueUsuario = this.user.tipoCorretor === corretoraProposta.tipoCorretora;
        if(!verificaCorretoraMesmoTipoQueUsuario) {
          this.$root.$snackBar.open({
            color: "error",
            message:
              "Não é possível transmitir a proposta. Verifique o vínculo com sua corretora.",
          });
          return;
        }
        if (this.etapa.id === 'aguardando_envio_anexos' || this.etapa.id === 'aguardando_aceite_anexos') {
          this.$root.$snackBar.open({
            color: "warning",
            message:
              "Para transmitir, é necessário que o cliente assine os anexos alterados.",
          });
          return;
        }

        if (this.vinculoProposta.perguntar) {
          this.$refs.vinculoProposta.openDialogPergunta();
          return;
        }

        this.transmitir();
      } catch (err) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao transmitir, tente novamente mais tarde",
        });
      }
    },
    async retransmitir() {
      try {
        // NECESSARIO TER O STATE DO ADENDO PME PARA IMPLEMENTAR ESSA LOGICA
        if (
          this.adendoState
          && this.adendoState.length > 0
          && this.proposta.etapa !== "adendo_assinado"
        ) {
          this.$root.$snackBar.open({
            color: "warning",
            message:
              "Para retransmitir é necessário que o cliente assine o adendo.",
          });
          return;
        }
        this.openDialog("ValidacaoDados");
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao retransmitir, tente novamente mais tarde",
        });
      }
    },
    async closeDialog() {
      this.dialog = false;
      this.dadosSelected = "DadosEmpresa";
      await this.recarregarDados();
    },
    async recarregarDocumentos() {
      // await this.carregarDocumentos();
    },
    onClickIrParaAnexo() {
      this.dialogAnexo = false;
      this.openDialog("Anexos");
    },
    checkAnexoObrigatorioPreenchido() {
      const docArray = [];
      this.documentos.forEach((docs) => {
        const docsObrigatorios = docs.tiposDocumentos.filter((doc) => doc.grupo !== "Outros");
        const totalPreenchido = docsObrigatorios.filter((doc) => doc.preenchido);
        if (totalPreenchido.length) docArray.push(true);
        else docArray.push(false);
      });
      return docArray.every((element) => element === true);
    },
    async onClickEnviarParaAssinatura(botao) {
      const isValidAnexoObrigatorio = this.checkAnexoObrigatorioPreenchido();
      const { flagImportacaoAuto } = this.proposta;
      if (!isValidAnexoObrigatorio && !flagImportacaoAuto) {
        this.$root.$snackBar.open({
          color: "warning",
          message: "Deve ser preenchido ao menos um anexo obrigatório para cada beneficiário",
        });
        return;
      }

      if (botao === "Enviar") {
        try {
          this.dialogAnexo = false;
          const { guid: guidProposta } = this.proposta;
          this.botaoInfos.loading = true;
          await propostaPMEService.assinaturaEnvio(guidProposta);
          this.botaoInfos.loading = false;
          this.desabilitaPor(this.botaoInfos, 40);
          this.$root.$snackBar.open({
            color: "success",
            message: "Enviado!",
          });
          this.recarregarDados();
        } catch (error) {
          this.botaoInfos.loading = false;
          this.$root.$snackBar.open({
            color: "error",
            message: error.message,
          });
        }
      } else if (botao === "Reenviar") {
        try {
          this.dialogAnexo = false;
          const proposta = await this.buscarDadosProposta();
          if (proposta?.etapa?.toLowerCase() === "contrato_assinado") {
            this.$root.$snackBar.open({
              color: "error",
              message: "Cliente já assinou a proposta",
            });
            this.recarregarDados();
            return;
          }
          const { guid: guidProposta } = this.proposta;
          this.botaoInfos.loading = true;
          await propostaPMEService.assinaturaEnvio(guidProposta);
          this.botaoInfos.loading = false;
          this.desabilitaPor(this.botaoInfos, 40);
          this.$root.$snackBar.open({
            color: "success",
            message: "Reenviado!",
          });
          this.recarregarDados();
        } catch (error) {
          this.botaoInfos.loading = false;
          this.$root.$snackBar.open({
            color: "error",
            message: error.message,
          });
        }
      }
    },
    async carregarDocumentos() {
      try {
        const { guid } = this.propostaState;
        const { data } = await documentoService.getDocumentosPorParticipante({
          guidProposta: guid,
        });
        this.agruparBeneficiarios();
        this.agruparDocumentos(data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar documentos, tente novamente mais tarde",
        });
      }
    },
    agruparBeneficiarios() {
      this.beneficiarios.push({
        guid: this.titularState.guid,
        nome: this.titularState.nome,
        sexo: this.titularState.sexo,
        dataNascimento: this.titularState.dataNascimento,
      });
      this.dependentesState.forEach((dependente) => {
        this.beneficiarios.push({
          guid: dependente.guid,
          nome: dependente.nome,
          sexo: dependente.sexo,
          dataNascimento: dependente.dataNascimento,
        });
      });
      if (this.propostaState.responsavelLegal) {
        this.beneficiarios.push({
          guid: this.propostaState.responsavelLegal.guid,
          nome: this.propostaState.responsavelLegal.nome,
          sexo: this.propostaState.responsavelLegal.sexo,
          dataNascimento: this.propostaState.responsavelLegal.dataNascimento,
        });
      }
    },
    agruparDocumentos(data) {
      this.documentos = [];
      data.forEach((item, i) => {
        const [beneficiario] = this.beneficiarios.filter((ben) => ben.guid === item.guid);
        const retorno = {
          guid: item.guid,
          tipoBeneficiario: item.tipoBeneficiario,
          parentesco: item.parentesco,
          tiposDocumentos: [],
          beneficiario,
        };
        const { tiposDocumentos } = item;
        const grupos = tiposDocumentos.map(({ grupo }) => grupo);
        const distinctGrupos = grupos.filter((value, index, self) => self.indexOf(value) === index);
        distinctGrupos.forEach((itemDg) => {
          const docs = tiposDocumentos.filter(({ grupo, visivel }) => grupo === itemDg && visivel);
          const { habilitadoUpload } = tiposDocumentos.find((doc) => doc.grupo === itemDg);
          if (docs && docs.length > 0) {
            const arquivosTotal = docs.filter((doc) => doc.arquivos.length > 0);
            let preenchido = false;
            if (arquivosTotal.length > 0) preenchido = true;
            if (itemDg !== "Contrato") {
              retorno.tiposDocumentos.push({
                grupo: itemDg,
                documentos: docs,
                preenchido,
                habilitadoUpload,
              });
            }
          }
        });
        this.documentos.push(retorno);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.border-valorTotal {
  border-left-style: solid;
  border-left-width: thin;
  border-left-color: #e0e0e0;
}

.border-down {
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #e0e0e0;
}

.espacamento-linhas {
  line-height: 12px !important;
}

.espacamento-linhas16 {
  line-height: 16px !important;
}

.rowVerifPend:hover .hoverVerificarPendencias {
  text-decoration: underline !important;
}

#scroll-pull {
  overflow: hidden !important;
}
</style>