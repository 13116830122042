<template>
  <div class="mb-8 pb-8">
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Dados Financeiros
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Informações financeiras
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-0">
      <v-row no-gutters>
        <v-col cols="12" class="mb-2 mt-2">
          <b>Forma de pagamento</b> <br>
           {{ (propostaState.empresa.pagamento && propostaState.empresa.pagamento.formaPagamento &&
           propostaState.empresa.pagamento.formaPagamento.toUpperCase() === 'BOLETO' ? 'Boleto Digital' : 'Débito Automático')
           || '--' }}
        </v-col>
      </v-row>
      <v-row v-if="propostaState.empresa.pagamento.porcentagemTaxaAngariacao">
        <v-col cols="12" class="mb-2 mt-2">
          <h1 class="title font-weight-bold mb-1 mt-4 primary--text" style="line-height: 1">
            Tipo de Taxa de Angariação
          </h1>
          <v-divider class="mb-2 mt-2"></v-divider>
          <v-row>
            <v-col cols="6" class="mb-2 mt-2">
              {{ propostaState.empresa.pagamento.porcentagemTaxaAngariacao }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-footer fixed v-if="showEditButton">
      <v-col class="text-center pa-1" cols="12" >
        <v-btn large color="primary" class="secundaryBtn--text" @click="edit">
          Editar dados
        </v-btn>
      </v-col>
    </v-footer>

  </div>
</template>

<script>
import { Etapas } from '@/utils/etapas';
import { mapActions, mapState } from 'vuex';
// import qualiRecebeService from "@/services/qualiRecebeService";
import propostaPMEService from '@/services/propostaPMEService';

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  data: () => ({
    etapaFinanceiro: {},
    taxaAngariacao: {
      status: 'EM ABERTO'
    },
    pagamentoStatus: null,
    enviandoLink: false,
  }),
  computed: {
    ...mapState({
      propostaState: (state) => state.PME,
    }),
    showEditButton() {
      const statusEdit = ["EM_DIGITACAO", "DEVOLVIDA"];
      if(statusEdit.includes(this.propostaState.empresa.status) && this.showBotao) return true;

      return false;
    },
    pagamentoLinkHabilitado() {
      let { valorTaxaAngariacao } = this.propostaState;
      valorTaxaAngariacao = valorTaxaAngariacao || 0;
      // const pagamentoDisponivel = this.getPagamentoDisponivel();
      const { status } = this.taxaAngariacao;
      return status !== 'Pago' && valorTaxaAngariacao > 0 && status !== 'Pendente';
    },
  },
  async created() {
    // await this.getStatusPagamento()
  },
  mounted() {    
    // this.detalhesPagamento();
  },
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
      setStatusPagamento: 'resumoProposta/setStatusPagamento',
      // enviarLinkPagamento: 'resumoProposta/enviarLinkPagamento',
    }),
    // async getStatusPagamento() {
    //   const { tipoTaxaAngariacao } = this.propostaState;
    //   const verificarStatusTaxa = tipoTaxaAngariacao.toUpperCase() === 'COBRANCA'
    //     || false;
    //   if (verificarStatusTaxa) {
    //     const res = await this.setStatusPagamento();
    //     this.taxaAngariacao.status = res ? res.toUpperCase() : 'EM ABERTO';
    //   }
    // },
    // async detalhesPagamento() {
    //   const pagamentoDisponivel = this.getPagamentoDisponivel(this.proposta);
    //   if (pagamentoDisponivel) {
    //     const pagamentos = await qualiRecebeService.getDetalhesPagamento(this.propostaState.nrProposta);
    //     if (pagamentos.length === 0) {
    //       this.taxaAngariacao.status = "Aguardando Pagamento";
    //     } else {
    //       const pagamentoVigente = await this.getPagamentoVigente(pagamentos);
    //       let { tipoPagamento, status, parcelas } = pagamentoVigente;
    //       console.log(tipoPagamento);
    //       tipoPagamento ||= '--';
    //       status = this.getTipoStatus(status);
    //       parcelas ||= 1;
    //       this.taxaAngariacao = {
    //         status,
    //         tipoPagamento: tipoPagamento.toLowerCase(),
    //         parcelas,
    //       };
    //     }
    //   }
    // },
    // getTipoStatus(status) {
    //   status = status.toString().toLowerCase();
    //   switch (status) {
    //   case 'pendente':
    //     return 'Aguardando Pagamento';
    //   case 'pago':
    //     return 'Pago';
    //   case 'expirado':
    //     return 'Link Expirado';
    //   default:
    //     return status;
    //   }
    // },
    // getPagamentoDisponivel() {
    //   const { etapaTratada, status } = this.propostaState;
    //   return etapaTratada.toUpperCase() !== 'EM_DIGITACAO' || status.toUpperCase() === 'AGUARDANDO ASSINATURA';
    // },
    getTipoPagamento(id) {
      id = id ? (id.toString()).toUpperCase() : id;
      switch (id) {
      case '1':
      case 'CREDITO':
        return 'Cartão de Crédito';
      case '2':
      case 'BOLETO':
        return 'Boleto';
      default: return '--';
      }
    },
    // async getPagamentoVigente(pagamentos) {
    //   let pagamentoVigente = await pagamentos.findIndex((pagamento) => (pagamento.status).toLowerCase() === 'pago');
    //   if (pagamentoVigente === -1) {
    //     pagamentoVigente = 0;
    //   }
    //   pagamentoVigente = pagamentos[pagamentoVigente];
    //   return pagamentoVigente;
    // },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      this.$router.push({ name: 'areaLogada.PME', params: { guid: this.$route.params.guid }, query: { etapa: "dados_financeiros" } });

      // this.carregarEtapa();
      // this.setBlnContinuar({ flag: true, page: this.etapaFinanceiro.ordem, isEditing: true });
      // this.$router.push({ name: 'areaLogada.criarProposta' });
    },
    // async enviarLink() {
    //   try {
    //     this.enviandoLink = true;
    //     await this.enviarLinkPagamento();
    //     this.enviandoLink = false;
    //     this.$root.$snackBar.open({
    //       color: 'success',
    //       message: 'O link de pagamento foi enviado',
    //     });
    //   } catch (error) {
    //     this.$root.$snackBar.open({
    //       color: 'error',
    //       message: 'Erro ao enviar, tente novamente mais tarde',
    //     });
    //   }
    // },
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.etapaFinanceiro = objEtapas.getEtapaAtual("dados_financeiros");
    },
    tipoTaxaAngariacao(tipo) {
      switch (tipo) {
      case 'ISENCAO':
        return 'ISENTO';
      case 'COBRANCA':
        return 'COBRANÇA';
      default:
        return tipo;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

</style>
