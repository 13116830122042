var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Adendo ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Relação de dados alterados ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-2 mb-10" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center mb-2", attrs: { cols: "12" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.adendoState.length) +
                      " alteração(ões) registrada(s) "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._l(_vm.adendo, function (item, i) {
            return _c(
              "v-card",
              { key: i, staticClass: "mb-4" },
              [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "3", lg: "1" },
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "primary", size: "55" } },
                              [_vm._v("mdi-account-circle-outline")]
                            ),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "9", lg: "11" } }, [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold primary--text" },
                            [_vm._v(" " + _vm._s(item.beneficiario) + " ")]
                          ),
                          _c("br"),
                          _c("span", { staticClass: "primary--text" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.dados.length) +
                                " alteração(ões) "
                            ),
                          ]),
                          _c("br"),
                        ]),
                      ],
                      1
                    ),
                    _c("v-simple-table", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "text-left subtitle-1 font-weight-bold",
                                      },
                                      [_vm._v(" Campo ")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "text-left subtitle-1 font-weight-bold",
                                      },
                                      [_vm._v(" De ")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "text-left subtitle-1 font-weight-bold",
                                      },
                                      [_vm._v(" Para ")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(item.dados, function (dado, d) {
                                    return _c("tr", { key: d }, [
                                      _c("td", [
                                        _vm._v(_vm._s(dado.descricao)),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatarValoresNovos(
                                              dado.valorAnterior,
                                              dado.campo
                                            )
                                          )
                                        ),
                                      ]),
                                      dado.campo === "documento"
                                        ? _c(
                                            "td",
                                            [
                                              _vm.checkIfDocumentIsPdf(
                                                dado.link
                                              )
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "anexos-image-pdf-icon",
                                                      attrs: { size: 40 },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.zoom(
                                                            dado.link,
                                                            dado.valorNovo,
                                                            false,
                                                            dado.campo
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("mdi-file-pdf-box")]
                                                  )
                                                : _c(
                                                    "v-img",
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "anexos-image",
                                                        attrs: {
                                                          height: 80,
                                                          width: 80,
                                                          position: "top",
                                                          src: dado.link,
                                                          alt: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.zoom(
                                                              dado.link,
                                                              dado.valorNovo,
                                                              false,
                                                              dado.campo
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-img",
                                                      _vm.$attrs,
                                                      false
                                                    )
                                                  ),
                                            ],
                                            1
                                          )
                                        : _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatarValoresNovos(
                                                  dado.valorNovo,
                                                  dado.campo
                                                )
                                              )
                                            ),
                                          ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm.showBotao
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text mr-2",
                      attrs: {
                        large: "",
                        color: "secondary",
                        loading: _vm.loadingCancelar,
                      },
                      on: { click: _vm.cancelarAdendo },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm.botaoInfos.nome
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "secundaryBtn--text",
                          attrs: {
                            large: "",
                            color: "primary",
                            disabled:
                              _vm.botaoInfos.segundosParaHabilitar > 0 ||
                              _vm.loadingCancelar,
                          },
                          on: { click: _vm.botaoInfos.acao },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.botaoInfos.segundosParaHabilitar > 0 &&
                                  !_vm.loading
                                  ? `${_vm.botaoInfos.nome} ${_vm.botaoInfos.segundosParaHabilitar}`
                                  : `${_vm.botaoInfos.nome}`
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.preview.show,
            callback: function ($$v) {
              _vm.$set(_vm.preview, "show", $$v)
            },
            expression: "preview.show",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "", dark: "", color: "black" } },
            [
              _c(
                "v-app-bar",
                { attrs: { color: "black", dark: "", fixed: "" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "d-flex align-center pa-0 pa-md-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3",
                          attrs: { small: "", icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.preview.show = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                        1
                      ),
                      _c("v-toolbar-title", { staticClass: "ml-2" }, [
                        _vm._v("Documento"),
                      ]),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { height: "70px" } }),
              _c(
                "v-container",
                { staticClass: "d-flex justify-center pa-4" },
                [
                  _c("v-img", {
                    attrs: {
                      "max-width": "800",
                      width: "100%",
                      src: _vm.preview.file,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }