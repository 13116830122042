<template>
    <div>
        <v-toolbar dark dense :color="$store.getters.getColor('primary')" fixed>
            <v-btn icon @click="backPage">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class="espacamento-linhas pt-1">
                <span>Resumo</span> <br />
                <span class="caption espacamento-linhas">Detalhes da Proposta</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container class="pa-5 mt-4">
            <div v-if="loading" class="d-flex justify-center mt-10 pa-10">
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </div>
            <div v-else class="body-container-pme">
                <v-col class="pa-2 pa-5 mt-4" cols="12" md="4" @click="getDatasPme()">
                    <base-button-text :data-test-id="'empresa'" title="EMPRESA" :text="proposta.razaoSocial"
                        :color="true" :vidas="[]"></base-button-text>
                </v-col>
                <v-col class="pa-2 pa-5 mt-4" cols="12" md="4" v-for="(item, index) in proposta.propostas" :key="index"
                    @click="getDatas(item.guid)">
                    <base-button-text title="TITULAR" :status="item.etapa" :vidas="item.dependentes"
                        :data-test-id="'beneficiarios'" :text="item.titular.nome"></base-button-text>
                </v-col>
            </div>
        </v-container>
        <v-btn v-if="showButton" fixed dark right bottom fab class="mt-10" style="bottom: 18px" @click="openDialog()">
            <img :src="adicionaBeneficiario" width="60" height="60" />
        </v-btn>
    </div>
</template>

<script>
import BaseButtonText from '@/components/BaseButtonText.vue';
import empresaService from '@/services/empresaService';
import AdicionaBeneficiario from '@/assets/img/icone_adicionar.svg'
import { mapActions } from "vuex";

export default {
    components: { BaseButtonText },
    name: "ResumoEmpresa",
    data() {
        return {
            proposta: {
                razaoSocial: ''
            },
            adicionaBeneficiario: "",
            dialog: false,
            loading: false
        }
    },
    created() {
        this.adicionaBeneficiario = AdicionaBeneficiario
    },
    mounted() {
        this.getProposta()
    },
    computed: {
        showButton() {
            const list = ['em_analise' ,'devolvida' ,'cancelada' ,'implantada']

            return !list.includes(this.proposta.status.toLowerCase()) && this.proposta.propostas.length < 29
        }
    },
    methods: {
        ...mapActions({
            setEmpresa: 'PME/setEmpresa',
            setCorretora: 'PME/setCorretora',
            setOperadora: 'PME/setOperadora',
            setVigencia: 'PME/setVigencia',
            setGuid: 'PME/setGUID',
            resetDadosProposta: 'cadastroProposta/resetDadosProposta'
        }),
        getDatasPme() {
            const { guid: guid } = this.$route.params;
            this.$router.push({ name: 'areaLogada.dadosPropostaPME', params: { guid } });
        },
        getDatas(guid) {
            this.$router.push({ name: 'areaLogada.dadosPropostaAdesaoPME', params: { guidPME: this.$route.params.guid, guid } });
        },
        async getProposta() {
            try {
                this.loading = true;
                const { guid } = this.$route.params;
                const data = await empresaService.getByGuid(guid);
                this.proposta = data;
            } catch (error) {
                this.$root.$snackBar.open({
                    color: "error",
                    message: "Proposta não encontrada",
                });
            } finally {
                this.loading = false;
            }
        },
        openDialog() {
            this.$router.push({ name: "areaLogada.propostaAdesaoPME", params: { guidPME: this.$route.params.guid } });
        },
        backPage() {
            this.$router.push({ name: "areaLogada.propostasPME" });
        },

    }
}
</script>

<style>
.espacamento-linhas {
    line-height: 12px !important;
}

.body-container-pme {
    display: flex;
    flex-wrap: wrap;
}
</style>