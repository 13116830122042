<template>
  <div>
    <v-dialog :retain-focus="false" no-click-animation v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <component v-if="loading" ref="component" :is="pages[step].component" @next="nextStep" @back="backStep" @openmenu="menu = true"></component>
      </v-card>
    </v-dialog>
    <v-navigation-drawer v-model="menu" fixed right temporary width="330">
      <v-card tile dark color="primary">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title text-center pa-3">Contratação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <div class="scrollbar">
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Nº do contrato</b> <br />
            {{ dados.nrContrato || "--" }}
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Operadora</b> <br />
            {{ (dados.operadora && dados.operadora.nomeExibicaoOperadora) || "--" }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Vigência</b> <br />
            {{ dados.vigencia | convertDateOutput }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
      <template v-slot:append>
        <v-list tile dark elevation="6" class="pa-0" color="red darken-4" style="border-radius: 0">
          <v-list-item link class="pt-2 pb-2" @click="leave">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 mt-1">Voltar para home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PreventRealodOnEditing from "@/mixins/preventReloadOnEditing";
import TheTimelineSteps from "@/components/TheTimelineSteps.vue";
import Empresa from "./Empresa.vue";
import Operadora from "./Operadora.vue";
import Vigencias from "./Vigencias.vue";
import DadosFinanceiros from "./DadosFinanceiros.vue";
import Corretoras from "./Corretoras.vue";
import Documentos from "./Documentos/index.vue";
import empresaService from "@/services/empresaService";
import propostaPMEService from "@/services/propostaPMEService";
import { etapasToStep, etapasDevolvidasPodeEditar } from "@/utils/constants/pme";

const ETAPAS_DEVOLVIDA_PODE_EDITAR = etapasDevolvidasPodeEditar;


export default {
  components: {
    TheTimelineSteps,
  },
  mixins: [PreventRealodOnEditing],
  data() {
    return {
      pages: [
        { nome: "Associar Corretora", component: Corretoras }, // STEP 0
        { nome: "Empresa", component: Empresa }, // STEP 1
        { nome: "Seleção operadora", component: Operadora }, // STEP 2
        { nome: "Vigencias", component: Vigencias }, // STEP 3
        { nome: "Dados Financeiros", component: DadosFinanceiros }, // STEP 4
        { nome: "Documentos", component: Documentos }, // STEP 5
      ], 
      step: 0,
      currentStep: 0,
      dialog: true,
      menu: false,
      dados: {},
      loading: false,
    };
  },
  watch: {
    step(newValue) {
      if (newValue < this.currentStep) return;
      this.currentStep = newValue;
    },
    PMEState: {
      immediate: true,
      handler: "setPME",
      deep: true,
    },
  },
  computed: {
    ...mapState({
      PMEState: (state) => state.PME,
    }),
  },
  async created() {
    this.loading = true;
    if(this.$route.params.guid) {
      const propostaPME = await empresaService.buscarInformacoesTelasCadastro(this.$route.params.guid)
      this.setDadosPME(propostaPME);
      if(propostaPME.isEditing) {
        this.step = etapasToStep[propostaPME.etapaEditing]
      } else if (ETAPAS_DEVOLVIDA_PODE_EDITAR.includes(this.PMEState.etapa)) {
        this.step = etapasToStep[this.$route.query.etapa];
      } else {
        if (etapasToStep[propostaPME.etapa]) {
          this.step = etapasToStep[propostaPME.etapa];
        } else {
          this.$root.$snackBar.open({
            color: "error",
            message: "Erro ao buscar etapa do contrato",
          });
          this.$router.go(-1);
        }
        
      }
    } else {
      this.resetPME();
    }
  },
  methods: {
    ...mapActions({
      setDadosPME: "PME/setDadosPME",
      resetDadosPME: "PME/resetDadosPME",
    }),
    setPME() {
      this.dados = {
        ...this.PMEState,
      };
    },
    async nextStep() {
      if(this.PMEState.isEditing){
        await this.backToResume()
      } else if (this.step === 1 && !this.$route.params.guid) {
        this.$router.push({ name: "areaLogada.PME", params: { guid: this.PMEState.guid } });
        this.step++;
      } else if (ETAPAS_DEVOLVIDA_PODE_EDITAR.includes(this.PMEState.etapa)) {
        this.$router.push({ name: "areaLogada.dadosPropostaPME", params: { guid: this.PMEState.guid } });
      } else {
        this.step++;
      }
    },
    async backStep() {
      if(this.PMEState.isEditing) {
        await this.backToResume()
      } else if (ETAPAS_DEVOLVIDA_PODE_EDITAR.includes(this.PMEState.etapa)) {
        this.$router.push({ name: "areaLogada.dadosPropostaPME", params: { guid: this.PMEState.guid } });
      } else if (this.step > 0) {
        this.step--;
      } else {
        this.$router.go(-1);
      }
    },
    leave() {
      this.menu = false;
      this.$router.push("/");
    },
    resetPME() {
      this.resetDadosPME();
    },
    async backToResume() {
      await propostaPMEService.isEditing({ guid: this.$route.params.guid, isEditing: false, etapaEditing: "" })
      this.$router.push({ name: 'areaLogada.resumoPropostaPME', params: { guid: this.$route.params.guid } });
    }
  },
};
</script>

<style scoped lang="scss">
.v-dialog__content.v-dialog__content--active {
  z-index: 6 !important;
}

.scrollbar {
  width: 100%;
  height: calc(100% - 150px);
  position: fixed;
  overflow-y: scroll;
}
</style>
