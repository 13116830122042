import api from '@/services/api';
import AppError from '@/utils/appError';

const consultarTaxaAssociativa = async (idProdutoFatura) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ENTIDADE_API_KEY } };
    const { data: response } = await api.get(`entidades/taxa-associativa/${idProdutoFatura}`, config);    
    return response && response.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
}
const consultarEntidadePorOperadora = async (operadora) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ENTIDADE_API_KEY } };
    const { data: response } = await api.get(`entidades/operadoras/${operadora}`, config);    
    return response && response.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
}

export default {
  consultarTaxaAssociativa,
  consultarEntidadePorOperadora
}