exports.etapasToStep = {
    "dados_empresa": 1,
    "selecao_operadora": 2,
    "selecao_vigencia": 3,
    "dados_empresa": 1,
    "dados_financeiros": 4,
    "selecao_documentos": 5,
    "inclusao_beneficiarios": 5,
    "clonada": 0,
    "clonada_sem_supervisor": 0,
}

exports.etapasDevolvidasPodeEditar = ["contrato_devolvido", "adendo_recusado"];
