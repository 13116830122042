import api from '@/services/api'
import AppError from '@/utils/appError'

const resource = 'empresas'

const get = async({statusAgrupado, filtros = {}, page = 1, limit = 10}) => {
    try {
      if (statusAgrupado) filtros.statusAgrupado = statusAgrupado.toUpperCase();
      const config = {
        params: {
          filtros,
          limit,
          page
        },
        headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } 
      };

      const { data } = await api.get(`${resource}`, config);
      const list = data && data.data ? data.data : [];

      return list;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
}

const getByGuid = async(guid) => {
    try {
        const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
        const { data } = await api.get(`${resource}/${guid}`, config);
        const list = data && data.data ? data.data: [];

        return list;
      } catch (error) {
        const { response } = error;
        throw new AppError(response, 400);
      }
}

const assinaturaEnvio = async(guid) => {
  try {
      const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
      const { data } = await api.post(`${resource}/assinatura/envio/${guid}`, {}, config);
      const list = data && data.data ? data.data: [];

      return list;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
}

const associarProposta = async ({ idProposta, tipoUsuario, idUsuario }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    await api.post(`${resource}/associar`, { idProposta, tipoUsuario, idUsuario }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};


const validarPropostaPME = async (guid) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.post(`${resource}/checkout`, { guid }, config);
    const response = data && data.data ? data.data: {};
    return response;
  } catch (error) {
    const { response } = error;
    console.log(error)
    throw new AppError(response, 400);
  }
}

const cancelarProposta = async ({ id, justificativa }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    await api.put(`${resource}/${id}/cancelar`, { justificativa }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const getAdendo = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.get(`${resource}/${guidProposta}/adendo`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const clonarProposta = async ({ guidProposta, naoTemSupervisor }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.post(`${resource}/${guidProposta}/clonar`, { naoTemSupervisor }, config)
    return data
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const enviarAssinaturaAdendo = async ({ guidProposta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    await api.post(`${resource}/${guidProposta}/adendo/enviar-assinatura`, {}, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const isEditing = async ({ guid, isEditing, etapaEditing }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.post(`${resource}/${guid}/isEditing`, { guid, isEditing, etapaEditing }, config);
    return data
  } catch (error) {
    console.log(error);
    throw new Error("Não foi possível atualizar etapa da Proposta PME.");
  }
}

const getPendencias = async ({ guidProposta}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.get(`${resource}/${guidProposta}/pendencias`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
}

const recusarAdendo = async ({ guid }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    await api.post(`${resource}/adendo/recusar`, { guid }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

const cancelarAdendo = async ({ guid }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    await api.post(`${resource}/adendo/cancelar`, { guid }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response);
  }
};

export default {
    get,
    getByGuid,
    getAdendo,
    getPendencias,
    assinaturaEnvio,
    validarPropostaPME,
    associarProposta,
    cancelarProposta,
    clonarProposta,
    isEditing,
    validarPropostaPME,
    enviarAssinaturaAdendo,
    recusarAdendo,
    cancelarAdendo
}