var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            dark: "",
            dense: "",
            color: _vm.$store.getters.getColor("primary"),
            fixed: "",
          },
        },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.backPage } },
            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
            1
          ),
          _c("v-toolbar-title", { staticClass: "espacamento-linhas pt-1" }, [
            _c("span", [_vm._v("Resumo")]),
            _vm._v(" "),
            _c("br"),
            _c("span", { staticClass: "caption espacamento-linhas" }, [
              _vm._v("Detalhes da Proposta"),
            ]),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPendencias,
                  expression: "showPendencias",
                },
              ],
              staticClass: "mr-2",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.openDialog("DadosPendencias")
                },
              },
            },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    color: "red",
                    overlap: "",
                    content: _vm.pendenciasState.length,
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" mdi-information-outline "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            { attrs: { text: "" }, on: { click: _vm.recarregarDados } },
            [_vm._v(" Atualizar ")]
          ),
        ],
        1
      ),
      _c("div", { attrs: { id: "scroll-pull" } }, [
        _vm.loadingDatas
          ? _c(
              "div",
              { staticClass: "d-flex justify-center mt-10 pa-10" },
              [
                _c("v-progress-circular", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "pl-md-5 pr-md-5" },
              [
                _c(
                  "v-row",
                  { staticClass: "border-down", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center pa-2 mt-3 mb-3",
                        attrs: { md: "6" },
                      },
                      [
                        _c("b", [_vm._v("CNPJ")]),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("cnpjCpfMask")(_vm.proposta.cnpj)) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center pa-4 border-valorTotal",
                        attrs: { md: "6" },
                      },
                      [
                        _c("b", [_vm._v("Status")]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.retornaStatus) + " "),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "6", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4 mr-4",
                            attrs: { outlined: "", height: "100%" },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas16 pa-2" },
                              [
                                _c(
                                  "v-row",
                                  {
                                    class: { "mb-9": _vm.showJustificativa },
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pa-4",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Data criação")]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("convertDatetimeFormat")(
                                                _vm.proposta.dataCriacao
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pa-4",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Data alteração")]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("convertDatetimeFormat")(
                                                _vm.proposta.dataAlteracao
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    class: { "mb-9": _vm.showJustificativa },
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pa-4",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Corretora")]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (_vm.proposta.corretora &&
                                                _vm.proposta.corretora
                                                  .razaoSocial) ||
                                                "--"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "text-center pa-2 mt-3 mb-3",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Valor total")]),
                                        _c("br"),
                                        _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.proposta.valorContrato
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pa-4",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Cliente")]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (_vm.proposta.razaoSocial &&
                                                _vm.proposta.razaoSocial) ||
                                                "--"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pa-4",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Celular")]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (_vm.celularTitular &&
                                                _vm.celularTitular) ||
                                                (_vm.celularResponsavelLegal &&
                                                  _vm.celularResponsavelLegal
                                                    .numero) ||
                                                "--"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.proposta.corretoraRepasse &&
                                _vm.proposta.corretoraRepasse.id
                                  ? _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center pa-4",
                                            attrs: { md: "6" },
                                          },
                                          [
                                            _c("b", [
                                              _vm._v("Corretora de repasse"),
                                            ]),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (_vm.proposta
                                                    .corretoraRepasse &&
                                                    _vm.proposta
                                                      .corretoraRepasse.nome) ||
                                                    "--"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "6", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4 mr-4",
                            attrs: { outlined: "", height: "100%" },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas16 pa-2" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pa-4",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Passo atual")]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("etapaAdesaoPme")(
                                                _vm.proposta.etapa
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.dadosAssinatura,
                                                expression: "dadosAssinatura",
                                              },
                                            ],
                                            staticClass: "mr-2",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openDialog(
                                                  "DadosAssinaturaDps"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            !_vm.verificaAssinaturaPendente
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "warning" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "success" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-checkbox-marked-circle-outline"
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-dialog",
                                          {
                                            attrs: { width: "500" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _vm.etapa ===
                                                    "erro_validacao"
                                                      ? _c(
                                                          "span",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "cursor-pointer",
                                                              },
                                                              "span",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "warning",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-alert"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.dialogWarning,
                                              callback: function ($$v) {
                                                _vm.dialogWarning = $$v
                                              },
                                              expression: "dialogWarning",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c(
                                                  "v-card-title",
                                                  {
                                                    staticClass:
                                                      "text-h5 grey lighten-2",
                                                  },
                                                  [_vm._v(" Aviso ")]
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "mt-5" },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "Encontramos erros ou pendências para esta proposta."
                                                      ),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        'Ajuste os itens apontados ou clique em "Revalidar" para confirmar se está tudo certo para enviar ao cliente.'
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c("v-divider"),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "secundaryBtn--text",
                                                        attrs: {
                                                          color: "primary",
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.dialogWarning = false
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Entendi ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "left pa-1",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _vm.passoAtual === "Implantada"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "text-align": "justify",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "A proposta ainda será analisada pela operadora, por favor, aguarde o status "
                                                ),
                                                _c("b", [_vm._v("Finalizado")]),
                                                _vm._v(
                                                  " para notificar seu cliente."
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pa-4",
                                        attrs: { md: "6" },
                                      },
                                      [
                                        _vm.botaoInfos.nome
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "white--text",
                                                attrs: {
                                                  color: _vm.botaoInfos.color,
                                                  loading:
                                                    _vm.botaoInfos.loading,
                                                  disabled:
                                                    _vm.botaoInfos
                                                      .segundosParaHabilitar >
                                                      0 ||
                                                    _vm.botaoInfos.loading,
                                                },
                                                on: {
                                                  click: _vm.botaoInfos.acao,
                                                },
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.botaoInfos
                                                        .segundosParaHabilitar >
                                                        0
                                                        ? `${_vm.botaoInfos.nome} (${_vm.botaoInfos.segundosParaHabilitar})`
                                                        : `${_vm.botaoInfos.nome}`
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pa-4",
                                        attrs: { cols: "6", md: "6", sm: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Data da vigência")]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.dataVigenciaReturn) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "6", md: "6", sm: "6" },
                                      },
                                      [
                                        _vm.mostrarBotaoAnexoCliente
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "white--text",
                                                attrs: {
                                                  color:
                                                    _vm.botaoEnviarAnexo.color,
                                                  loading:
                                                    _vm.botaoEnviarAnexo
                                                      .loading,
                                                  disabled:
                                                    _vm.botaoEnviarAnexo
                                                      .segundosParaHabilitar >
                                                      0 ||
                                                    _vm.botaoEnviarAnexo
                                                      .loading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.solicitarAnexoCliente()
                                                  },
                                                },
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.botaoEnviarAnexo
                                                        .segundosParaHabilitar >
                                                        0
                                                        ? `${_vm.botaoEnviarAnexo.nome} (${_vm.botaoEnviarAnexo.segundosParaHabilitar})`
                                                        : `${_vm.botaoEnviarAnexo.nome}`
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.showJustificativa
                                  ? _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center pa-4",
                                            attrs: { md: "6" },
                                          },
                                          [
                                            _c("b", [_vm._v("Justificativa")]),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.proposta.etapa
                                                    .justificativa
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("v-col", {
                                          staticClass: "text-center pa-4",
                                          attrs: { md: "6" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.proposta.etapa &&
                _vm.proposta.etapa === "finalizada" &&
                _vm.proposta.titular.numeroCarteirinha
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "mt-2",
                            attrs: { cols: "12", md: "12", sm: "12" },
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "ml-4 mr-4",
                                attrs: { outlined: "", height: "100%" },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "espacamento-linhas16 pa-2" },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-left pa-4",
                                            attrs: { md: "12" },
                                          },
                                          [
                                            _c("b", [
                                              _vm._v("Número de Carteirinhas"),
                                            ]),
                                            _c("br"),
                                            _vm.proposta &&
                                            _vm.proposta.titular &&
                                            _vm.proposta.titular.nome &&
                                            _vm.proposta.titular
                                              .numeroCarteirinha
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-left mt-2",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Titular "),
                                                    ]),
                                                    _c("br"),
                                                    _c(
                                                      "div",
                                                      { staticClass: "mt-2" },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Nome: "),
                                                        ]),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.proposta.titular
                                                              .nome || "--"
                                                          )
                                                        ),
                                                        _c("br"),
                                                        _c("b", [
                                                          _vm._v(
                                                            "Carteirinha: "
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          _vm._s(
                                                            (_vm.proposta
                                                              .titular &&
                                                              _vm.proposta
                                                                .titular
                                                                .numeroCarteirinha) ||
                                                              "--"
                                                          ) + " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.proposta.dependentes &&
                                            _vm.proposta.dependentes.length >
                                              0 &&
                                            _vm.proposta.dependentes.some(
                                              (item) =>
                                                item.numeroCarteirinha != null
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-left mt-2",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Dependentes "),
                                                    ]),
                                                    _c("br"),
                                                    _vm._l(
                                                      _vm.proposta.dependentes,
                                                      function (dep, index) {
                                                        return _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            dep &&
                                                            dep.nome &&
                                                            dep.numeroCarteirinha
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2",
                                                                  },
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Nome: "
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        dep.nome ||
                                                                          "--"
                                                                      )
                                                                    ),
                                                                    _c("br"),
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Carteirinha: "
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        dep.numeroCarteirinha ||
                                                                          "--"
                                                                      ) + " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showPendencias
                  ? _c(
                      "div",
                      { staticClass: "ml-4" },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "pa-4 rowVerifPend",
                            staticStyle: { cursor: "pointer" },
                            attrs: { "no-gutters": "" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog("DadosPendencias")
                              },
                            },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c("v-icon", { attrs: { color: "amber" } }, [
                                  _vm._v("mdi-alert-circle"),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "hoverVerificarPendencias ml-1",
                                  },
                                  [_vm._v("Verificar pendências")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  { staticClass: "mr-4", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAdendo,
                            expression: "showAdendo",
                          },
                        ],
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "4", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4",
                            attrs: { outlined: "", height: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog("DadosAdendo")
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas pa-2" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center mt-1",
                                        attrs: { cols: "2", md: "3" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "45" } },
                                          [_vm._v("mdi-square-edit-outline")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { cols: "8", md: "7" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle-2 mb-0" },
                                          [_vm._v("Adendo")]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption mt-0 espacamento-linhas",
                                          },
                                          [_vm._v("Relação de dados alterados")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "2" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "subtitle-1 pt-1 mt-1 secundaryBtn--text",
                                            attrs: {
                                              fab: "",
                                              small: "",
                                              color: "primary",
                                              elevation: "0",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.adendoState.length) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAnexosAlterados,
                            expression: "showAnexosAlterados",
                          },
                        ],
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "4", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4",
                            attrs: { outlined: "", height: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog("AnexosAlterados")
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas pa-2" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center mt-1",
                                        attrs: { cols: "2", md: "3" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "45" } },
                                          [_vm._v("mdi-square-edit-outline")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { cols: "8", md: "7" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle-2 mb-0" },
                                          [_vm._v("Anexos alterados")]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption mt-0 espacamento-linhas",
                                          },
                                          [
                                            _vm._v(
                                              "Relação de anexos alterados"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "2" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "subtitle-1 pt-1 mt-1 secundaryBtn--text",
                                            attrs: {
                                              fab: "",
                                              small: "",
                                              color: "primary",
                                              elevation: "0",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.proposta
                                                    .qtdAnexosAlterados
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "4", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4",
                            attrs: { outlined: "", height: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog("DadosEmpresa")
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas pa-2" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-lg-center mt-1",
                                        attrs: { cols: "2", md: "3" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "45" } },
                                          [_vm._v("mdi-account")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { cols: "8", md: "7" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle-2 mb-0" },
                                          [_vm._v("Dados da Empresa")]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption mt-0 espacamento-linhas",
                                          },
                                          [
                                            _vm._v(
                                              "Informações pessoais e endereços"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "2" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-1",
                                            attrs: { icon: "" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "45" } },
                                              [_vm._v("mdi-chevron-right")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "4", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4",
                            attrs: { outlined: "", height: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog("DadosVigencia")
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas pa-2" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-lg-center mt-1",
                                        attrs: { cols: "2", md: "3" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "45" } },
                                          [_vm._v("mdi-calendar-month")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { cols: "8", md: "7" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle-2 mb-0" },
                                          [_vm._v("Dados Vigência")]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption mt-0 espacamento-linhas",
                                          },
                                          [
                                            _vm._v(
                                              "Informações sobre a vigência selecionada"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "2" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-1",
                                            attrs: { icon: "" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "45" } },
                                              [_vm._v("mdi-chevron-right")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "4", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4",
                            attrs: { outlined: "", height: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog("DadosFinanceiros")
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas pa-2" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-lg-center mt-1",
                                        attrs: { cols: "2", md: "3" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "45" } },
                                          [_vm._v("mdi-cash")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { cols: "8", md: "7" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle-2 mb-0" },
                                          [_vm._v("Dados Financeiros")]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption mt-0 espacamento-linhas",
                                          },
                                          [_vm._v("Informações financeiras")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "2" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-1",
                                            attrs: { icon: "" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "45" } },
                                              [_vm._v("mdi-chevron-right")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.propostaState &&
                    _vm.propostaState.titular &&
                    _vm.propostaState.titular.entidade &&
                    (_vm.$store.getters.getFiliacaoEntidadesPermitidas ==
                      null ||
                      _vm.$store.getters.getFiliacaoEntidadesPermitidas.includes(
                        _vm.propostaState.titular.entidade.toUpperCase()
                      ))
                      ? _c(
                          "v-col",
                          {
                            staticClass: "mt-4",
                            attrs: { cols: "12", md: "4", sm: "6" },
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "ml-4",
                                attrs: { outlined: "", height: "100%" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialog("DadosFiliacao")
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "espacamento-linhas pa-2" },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-lg-center mt-1",
                                            attrs: { cols: "2", md: "3" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "45" } },
                                              [_vm._v("mdi-content-paste")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-left",
                                            attrs: { cols: "8", md: "7" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "subtitle-2 mb-0",
                                              },
                                              [_vm._v("Ficha de Filiação")]
                                            ),
                                            _c("br"),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption espacamento-linhas",
                                              },
                                              [
                                                _vm._v(
                                                  "Informações sobre a ficha de filiação"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "2" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-1",
                                                attrs: { icon: "" },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "45" } },
                                                  [_vm._v("mdi-chevron-right")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.propostaState &&
                    _vm.propostaState.possuiPortabilidade &&
                    _vm.propostaState.portabilidadePorBeneficiario
                      ? _c(
                          "v-col",
                          {
                            staticClass: "mt-4",
                            attrs: { cols: "12", md: "4", sm: "6" },
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "ml-4",
                                attrs: { outlined: "", height: "100%" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialog("DadosPortabilidade")
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "espacamento-linhas pa-2" },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-lg-center mt-1",
                                            attrs: { cols: "2", md: "3" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "45" } },
                                              [_vm._v("mdi-account-switch")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-left",
                                            attrs: { cols: "8", md: "7" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "subtitle-2 mb-0",
                                              },
                                              [_vm._v("Dados Portabilidade")]
                                            ),
                                            _c("br"),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption espacamento-linhas",
                                              },
                                              [
                                                _vm._v(
                                                  "Informações de portabilidade dos beneficiários"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "2" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-1",
                                                attrs: { icon: "" },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "45" } },
                                                  [_vm._v("mdi-chevron-right")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "4", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4",
                            attrs: { outlined: "", height: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog("Anexos")
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas pa-2" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-lg-center mt-1",
                                        attrs: { cols: "2", md: "3" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "45" } },
                                          [_vm._v("mdi-paperclip")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { cols: "8", md: "7" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle-2 mb-0" },
                                          [_vm._v("Anexos")]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption espacamento-linhas",
                                          },
                                          [_vm._v("Relação de documentos")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "2" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-1",
                                            attrs: { icon: "" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "45" } },
                                              [_vm._v("mdi-chevron-right")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-4",
                        attrs: { cols: "12", md: "4", sm: "6" },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ml-4",
                            attrs: { outlined: "", height: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog("Contrato")
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "espacamento-linhas pa-2" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-lg-center mt-1",
                                        attrs: { cols: "2", md: "3" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "45" } },
                                          [_vm._v("mdi-file-document-multiple")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { cols: "8", md: "7" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle-2 mb-0" },
                                          [_vm._v("Contrato")]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption espacamento-linhas",
                                          },
                                          [_vm._v("Visualizar contrato")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "2" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-1",
                                            attrs: { icon: "" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "45" } },
                                              [_vm._v("mdi-chevron-right")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.proposta.status
                  ? _c(
                      "div",
                      [
                        _vm.proposta.status.toUpperCase() === "EM_DIGITACAO" ||
                        _vm.proposta.status.toUpperCase() === "DEVOLVIDA"
                          ? _c(
                              "v-row",
                              {
                                staticClass: "mt-5",
                                attrs: { justify: "center" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center",
                                    attrs: { cols: "12", md: "4" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "white--text elevation-0 px-10",
                                        attrs: {
                                          color: "red",
                                          depressed: "",
                                          elevation: "2",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCancelModal(true)
                                          },
                                        },
                                      },
                                      [_vm._v("Cancelar Proposta")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.proposta.status.toUpperCase() === "CANCELADA"
                          ? _c(
                              "v-row",
                              {
                                staticClass: "mt-2",
                                attrs: { justify: "center" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center",
                                    attrs: { cols: "12", md: "4" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "secundaryBtn--text elevation-0 px-10",
                                        attrs: {
                                          color: "primary",
                                          depressed: "",
                                          elevation: "2",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.propostaClonar.dialog = true
                                          },
                                        },
                                      },
                                      [_vm._v("Clonar Proposta")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("modal-confirmation", {
                          attrs: {
                            show: _vm.propostaClonar.dialog,
                            name: "clonar",
                            loading: _vm.propostaClonar.loading,
                            title: _vm.propostaClonar.title,
                            text: _vm.propostaClonar.text,
                          },
                          on: {
                            close: function ($event) {
                              _vm.propostaClonar.dialog = false
                            },
                            confirm: _vm.clonar,
                          },
                        }),
                        _c("modal-confirmation", {
                          attrs: {
                            show: _vm.propostaAssociar.dialog,
                            name: "associar",
                            loading: _vm.propostaAssociar.loading,
                            title: _vm.propostaAssociar.title,
                            text: _vm.propostaAssociar.text,
                          },
                          on: {
                            close: function ($event) {
                              _vm.propostaAssociar.dialog = false
                            },
                            confirm: _vm.associar,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.propostaCancelar.dialog,
            callback: function ($$v) {
              _vm.$set(_vm.propostaCancelar, "dialog", $$v)
            },
            expression: "propostaCancelar.dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "h2",
                  {
                    staticClass:
                      "primary--text mb-2 subtitle-1 font-weight-bold",
                  },
                  [_vm._v(" Cancelar Proposta ")]
                ),
              ]),
              _c(
                "validation-observer",
                {
                  ref: "formCancelarProposta",
                  attrs: { tag: "form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.cancelarProposta.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.propostaCancelar.justificativa,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.propostaCancelar,
                                "justificativa",
                                $$v
                              )
                            },
                            expression: "propostaCancelar.justificativa",
                          },
                        },
                        _vm._l(_vm.justificativas, function (just, index) {
                          return _c(
                            "div",
                            { key: `dado-${index}` },
                            [
                              _c("v-radio", {
                                attrs: {
                                  "data-test-id": `dado-${index}`,
                                  label: just.valor,
                                  value: just.valor,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm.propostaCancelar.justificativa === "Outros"
                        ? _c("base-text-field", {
                            attrs: {
                              rules: "required",
                              id: "justificativa",
                              name: "justificativa",
                              label: "Justificativa",
                              minlength: "5",
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.justificativaDissertativa,
                              callback: function ($$v) {
                                _vm.justificativaDissertativa = $$v
                              },
                              expression: "justificativaDissertativa",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: { color: "red darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.openCancelModal(false)
                            },
                          },
                        },
                        [_vm._v(" cancelar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secundaryBtn--text",
                          attrs: {
                            color: "primary",
                            text: "",
                            type: "submit",
                            loading: _vm.propostaCancelar.loading,
                          },
                        },
                        [_vm._v(" confirmar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            "no-click-animation": "",
            persistent: "",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c(_vm.pages[_vm.dadosSelected], {
                ref: "component",
                tag: "component",
                attrs: {
                  validarEdicao: _vm.validarEdicaoProposta,
                  validarEnvioAssAdendo: _vm.validarEnvioAssAdendo,
                  showBotao: _vm.showBotao,
                  anexarArquivos: _vm.anexarArquivos,
                  excluirArquivos: _vm.excluirArquivos,
                  etapa: _vm.etapa,
                  data: _vm.dadosValidacao,
                  origem: "resumo",
                  matchConversao: _vm.vinculoProposta.matchConversao.valores,
                },
                on: {
                  closedialog: _vm.closeDialog,
                  recarregardocumentos: _vm.recarregarDocumentos,
                  showmsgeditar: _vm.showMensagemEditarProposta,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450", eager: "" },
          model: {
            value: _vm.dialogAnexo,
            callback: function ($$v) {
              _vm.dialogAnexo = $$v
            },
            expression: "dialogAnexo",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-0" },
            [
              _c(
                "v-sheet",
                {
                  staticStyle: {
                    "border-bottom-right-radius": "0",
                    "border-bottom-left-radius": "0",
                  },
                  attrs: { color: "primary pa-2" },
                },
                [
                  _c("h2", { staticClass: "white--text title text-center" }, [
                    _vm._v("Aviso"),
                  ]),
                ]
              ),
              _c("v-sheet", { staticClass: "pa-4 pt-6 pb-0" }, [
                _c("p", { staticClass: "subtitle-2 text-center mb-3" }, [
                  _vm._v(
                    "A inclusão de anexos após a assinatura do cliente acarretará na assinatura de um adendo concordando com a nova documentação inserida no contrato."
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-sheet",
                { staticClass: "d-flex", attrs: { color: "pa-2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: {
                        "data-test-id": "voltar-para-anexo",
                        text: "",
                        color: "primary",
                      },
                      on: { click: _vm.onClickIrParaAnexo },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold text-capitalize subtitle-1 grey--text",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.breakpoint.xs
                                  ? "Anexo"
                                  : "Ir para anexo"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: {
                        "data-test-id": "enviar-para-assinatura",
                        text: "",
                        color: "primary",
                        type: "submit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickEnviarParaAssinatura(
                            _vm.botaoInfos.nome
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold text-capitalize subtitle-1 blue--text",
                        },
                        [_vm._v("Enviar para assinatura")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }