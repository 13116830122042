import Vue from 'vue';
import {
  differenceInYears, parse, parseISO, format, add,
} from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { utcToZonedTime } from 'date-fns-tz';

const dataHoje = (y) => {
  const date = new Date();
  const timeZone = 'America/Sao_Paulo';
  const zonedDate = utcToZonedTime(date, timeZone);
  return format((zonedDate), 'yyyy-MM-dd HH:mm');
};

const diaSemanaAbreviado = (value) => {
  const data = format(parseISO(value), "EEEEEE", { locale: pt });
  return data;
};

const dataCompleta = (value) => {
  const data = format(parseISO(value), "eeee', ' dd 'de' LLLL 'de' yyyy", { locale: pt });
  return data.charAt(0).toUpperCase() + data.slice(1);
};

const horaFormatada = (value) => {
  const { data, adicionar } = value;
  const res = format(add(parseISO(data), { hours: adicionar || 0 }), "HH:mm");
  return res;
};

const currency = (value = 0) => {
  const formatter = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
};

const toFixed = (value = 0) => (value === 0 ? value : value.toFixed(2));

const avatarName = (value) => {
  const arr = value ? value.split(' ') : [];
  const first = arr[0] && arr[0][0] ? arr[0][0] : 'N/D';
  const second = arr[1] && arr[1][0] && first !== 'N/D' ? arr[1][0] : '';
  const name = `${first}${second}`;
  return name.toUpperCase();
};

const firstName = (value) => {
  if (!value) return value;
  if (value.indexOf(' ') < 0) return value;
  return value.split(' ')[0];
};

const removeSymbols = (value) => {
  if (value) {
    return value.replace(/[^a-zA-Z0-9]/g, '');
  }
  return '';
};

const simOuNao = (value) => (value ? 'sim' : 'não');

const dataEmIdade = (value) => `${differenceInYears(new Date(), parse(value, 'dd/MM/yyyy', new Date()))} anos`;

const convertDateInput = (value) => {
  if (!value) return '';
  const valueData = value.split(' ')[0];
  const dateArr = valueData.split('/');
  if (dateArr.length < 3) {
    const dateInvalidArr = valueData.split('-');
    if (dateInvalidArr.length < 3) return '';
    return `${dateInvalidArr[0]}-${dateInvalidArr[1]}-${dateInvalidArr[2]}`;
  }
  return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
};

const convertDatetimeFormat = (value) => {
  if (!value) return '';
  const date = parseISO(value);
  return format(
    date,
    "dd/MM/yyyy HH:mm",
  );
};

const unixToDate = (value) => format(new Date(value), 'yyyy-MM-dd HH:mm');

const convertDateOutput = (value) => {
  if (!value) return '--';
  const valueData = value.split(' ')[0];
  const dateArr = valueData.split('-');
  if (dateArr.length < 3) {
    const dateInvalidArr = valueData.split('/');
    if (dateInvalidArr.length < 3) return '';
    return `${dateInvalidArr[0]}/${dateInvalidArr[1]}/${dateInvalidArr[2]}`;
  }
  return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
};

const propostaMap = (value) => {
  value = !value ? {} : value;
  value.etapa = !value.etapa ? {} : value.etapa;
  const etapaId = value && value.etapa && value.etapa.id;
  switch (etapaId) {
  case 'basicos':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Dados Básicos';
    break;
  case 'corretora':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Associar Corretora';
    break;
  case 'operadora':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Seleção Operadora';
    break;
  case 'plano':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Seleção Plano';
    break;
  case 'vigencia':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Seleção Vigência';
    break;
  case 'dados_titular_contratacao':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Dados Titular';
    break;
  case 'dados_responsavel_contratacao':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Dados Responsável';
    break;
  case 'dados_profissionais':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Dados Profissionais';
    break;
  case 'dependentes_contratacao':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Dados Dependentes';
    break;
  case 'dados_financeiros':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Dados Financeiros';
    break;
  case 'anexo':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Anexo';
    break;
  case 'aguardando_assinatura':
    value.etapa.titulo = 'Preenchimento cliente';
    value.etapa.subtitulo = 'Aguardando Assinatura';
    break;
  case 'erro_validacao':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Erro Validação';
    break;
  case 'preenchido':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Preenchido';
    break;
  case 'assinatura_recusada':
    value.etapa.titulo = 'Análise corretor';
    value.etapa.subtitulo = 'Assinatura Recusada';
    break;
  case 'contrato_assinado':
    value.etapa.titulo = 'Proposta assinada';
    value.etapa.subtitulo = 'Pronta Transmissão';
    break;
  case 'pronta_transmissao':
    value.etapa.titulo = 'Proposta assinada';
    value.etapa.subtitulo = 'Pronta Transmissão';
    break;
  case 'aguardando_assinatura_adendo':
    value.etapa.titulo = 'Devolvida';
    value.etapa.subtitulo = 'Aguardando Adendo';
    break;
  case 'adendo_assinado':
    value.etapa.titulo = 'Devolvida';
    value.etapa.subtitulo = 'Adendo Assinado';
    break;
  case 'em_analise':
    value.etapa.titulo = 'Em análise';
    value.etapa.subtitulo = 'Transmissão';
    break;
  case 'devolvida':
    value.etapa.titulo = 'Devolvida';
    value.etapa.subtitulo = 'Devolvida';
    break;
  case 'adendo_recusado':
    value.etapa.titulo = 'Devolvida';
    value.etapa.subtitulo = 'Adendo Recusado';
    break;
  case 'fila_transmissao':
    value.etapa.titulo = 'Fila Transmissão';
    value.etapa.subtitulo = 'Fila Transmissão';
    break;
  case 'erro_transmissao':
    value.etapa.titulo = 'Fila Transmissão';
    value.etapa.subtitulo = 'Erro Transmissão';
    break;
  case 'cancelada':
    value.etapa.titulo = 'Cancelada';
    value.etapa.subtitulo = 'Cancelada';
    break;
  case 'implantada':
    value.etapa.titulo = 'Implantada';
    value.etapa.subtitulo = 'Implantada';
    break;
  case 'finalizada':
    value.etapa.titulo = 'Finalizada';
    value.etapa.subtitulo = 'Finalizada';
    break;
  case 'dados_portabilidade':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Dados Portabilidade';
    break;
  case 'produto_opcional':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Produtos Opcionais';
    break;
  case 'nao_recomendada':
    value.etapa.titulo = 'Não Recomendada';
    value.etapa.subtitulo = 'Não Recomendada';
    break;
  case 'solicitado_devolucao':
    value.etapa.titulo = 'Em análise';
    value.etapa.subtitulo = 'Solicitado Devolução';
    break;
  case 'clonada_sem_supervisor':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Clone de Proposta';
    break;
  case 'clonada':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Clone de Proposta';
    break;
  case 'aguardando_envio_anexos':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Aguardando envio de aceite de anexos';
    break;
  case 'aguardando_aceite_anexos':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Aguardando aceite de anexos';
    break;
  case 'anexos_recusados':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Anexos recusados pelo cliente';
    break;
  case 'anexos_aceitos':
    value.etapa.titulo = 'Em digitação';
    value.etapa.subtitulo = 'Anexos aceitos pelo cliente';
    break;
  case 'cancelada_pre_vigencia':
    value.etapa.titulo = 'Cancelada pré-vigência';
    value.etapa.subtitulo = 'Cancelada pré-vigência';
    break;
  default:
    break;
  }

  return value;
};

const getAge = (value) => {
  if ((value.toString()).indexOf('/') > 0) value = convertDateInput(value);
  const ageDifMs = Date.now() - new Date(value);
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

// valida se data formato é dd/mm/yyyy
const dateFormatValid = (value) => value.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);

const mapBeneficiario = (proposta) => {
  const beneficiarios = [];
  if (!proposta) return [];
  if (proposta.titular) {
    beneficiarios.push({
      chave: proposta.titular.nome ? proposta.titular.nome : 'Titular',
      dataNascimento: convertDateInput(proposta.titular.dataNascimento),
    });
  }

  for (let index = 0; index < proposta.dependentes.length; index++) {
    const dep = proposta.dependentes[index];
    let chave = '';
    if (dep.nome) chave = dep.nome;
    else if (dep.dataNascimento) chave = convertDateOutput(dep.dataNascimento);
    else chave = `Dependente ${index}`;

    beneficiarios.push({
      chave,
      dataNascimento: convertDateInput(dep.dataNascimento),
    });
  }
  return beneficiarios;
};

const propostaToJson = (proposta) => {
  const cloneProposta = { ...proposta };
  if (cloneProposta.produtosOpcionais != null) {
    if (typeof cloneProposta.produtosOpcionais[0] === 'object') {
      const { produtosOpcionais } = cloneProposta;
      const arrayIDProdutoFatura = produtosOpcionais.map((e) => e.idProdutoFatura);
      cloneProposta.produtosOpcionais = arrayIDProdutoFatura;
    }
  }

  return cloneProposta;
};

const replaceSpecialChars = (str) => {
  str = str.toLowerCase();
  str = str.replace(/[àáâãäåÀÁÂÃÄÅ]/, "a");
  str = str.replace(/[èéêêÈÉÊÊ]/, "e");
  str = str.replace(/[íîîÍÎÎ]/, "i");
  str = str.replace(/[óòôõÓÒÔÕ]/, "o");
  str = str.replace(/[úùûÚÙÛ]/, "u");
  str = str.replace(/[çÇ]/, "ç");

  return str.replace(/[^a-z0-9]/gi, '');
};

const calcularFaixaEtaria = (dataNascimento) => {
  var dataNascimento = new Date(dataNascimento);
  var hoje = new Date();
  var idade = hoje.getFullYear() - dataNascimento.getFullYear();
  var mesAtual = hoje.getMonth() + 1;
  var mesNascimento = dataNascimento.getMonth() + 1;
  
  if (mesAtual < mesNascimento || (mesAtual === mesNascimento && hoje.getDate() < dataNascimento.getDate())) {
      idade--;
  }
  
  switch (true) {
      case (idade >= 0 && idade <= 18):
          return "0-18 anos";
      case (idade >= 19 && idade <= 23):
          return "19-23 anos";
      case (idade >= 24 && idade <= 28):
          return "24-28 anos";
      case (idade >= 29 && idade <= 33):
          return "29-33 anos";
      case (idade >= 34 && idade <= 38):
          return "34-38 anos";
      case (idade >= 39 && idade <= 43):
          return "39-43 anos";
      case (idade >= 44 && idade <= 48):
          return "44-48 anos";
      case (idade >= 49 && idade <= 53):
          return "49-53 anos";
      case (idade >= 54 && idade <= 58):
          return "54-58 anos";
      case (idade >= 59 && idade <= 99):
          return "59 anos ou ++";
      default:
          return "Idade inválida";
  }
}
const cnpjCpfMask = (value) => {
  if(value && value.length === 11) {
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
  } else if( value && value.length === 14) {
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")
  }
  return value
}

const etapaAdesaoPme = (value) => {
  switch(value) {
    case 'basicos':
    return 'Em digitação'
    case 'corretora':
      return 'Em digitação';
    case 'operadora':
      return 'Em digitação';
    case 'plano':
      return 'Em digitação';
    case 'vigencia':
      return 'Em digitação';
    case 'dados_titular_contratacao':
      return 'Em digitação';
    case 'dados_responsavel_contratacao':
      return 'Em digitação';
    case 'dados_profissionais':
      return 'Em digitação';
    case 'dependentes_contratacao':
      return 'Em digitação';
    case 'dados_financeiros':
      return 'Em digitação';
    case 'anexo':
      return 'Em digitação';
    case 'aguardando_assinatura':
      return 'Preenchimento cliente';
    case 'erro_validacao':
      return 'Em digitação';
    case 'preenchido':
      return 'Em digitação';
    case 'assinatura_recusada':
      return 'Análise corretor';
    case 'contrato_assinado':
      return 'Proposta assinada';
    case 'pronta_transmissao':
      return 'Proposta assinada';
    case 'aguardando_assinatura_adendo':
      return 'Devolvida';
    case 'adendo_assinado':
      return 'Devolvida';
    case 'em_analise':
      return 'Em análise';
    case 'devolvida':
      return 'Devolvida';
    case 'adendo_recusado':
      return 'Adendo recusado';
    case 'fila_transmissao':
      return 'Fila Transmissão';
    case 'erro_transmissao':
      return 'Fila Transmissão';
    case 'cancelada':
      return 'Cancelada';
    case 'implantada':
      return 'Implantada';
    case 'finalizada':
      return 'Finalizada';
    case 'dados_portabilidade':
      return 'Em digitação';
    case 'produto_opcional':
      return 'Em digitação';
    case 'nao_recomendada':
      return 'Não Recomendada';
    case 'solicitado_devolucao':
      return 'Em análise';
    case 'clonada_sem_supervisor':
      return 'Em digitação';
    case 'clonada':
      return 'Em digitação';
    case 'aguardando_envio_anexos':
      return 'Em digitação';
    case 'aguardando_aceite_anexos':
      return 'Em digitação';
    case 'anexos_recusados':
      return 'Em digitação';
    case 'anexos_aceitos':
      return 'Em digitação';
    case 'nao_assinada':
      return 'Não assinada';
    case 'cancelada_pre_vigencia':
      return 'Cancelada pré-vigência';
    case 'inclusao_beneficiarios':
      return 'Inclusão de Beneficiários';
    case 'selecao_documentos':
      return 'Seleção de documentos';
    case 'selecao_vigencia':
      return 'Seleção de vigência';
    case 'aguardando_assinatura':
      return 'Aguardando Assinatura';
    case 'selecao_documentos':
      return 'Seleção de Documentos';
    case 'selecao_operadora':
      return 'Seleção de Operadora';
    case 'proposta_devolvida': 
      return 'Proposta Devolvida'
    case 'contrato_devolvido': 
      return 'Contrato Devolvido'
    default:
      return value
  }
}

Vue.filter('etapaAdesaoPme', etapaAdesaoPme)
Vue.filter('cnpjCpfMask', cnpjCpfMask);
Vue.filter('currency', currency);
Vue.filter('getAge', getAge);
Vue.filter('toFixed', toFixed);
Vue.filter('avatarName', avatarName);
Vue.filter('removeSymbols', removeSymbols);
Vue.filter('firstName', firstName);
Vue.filter('simOuNao', simOuNao);
Vue.filter('dataEmIdade', dataEmIdade);
Vue.filter('convertDateInput', convertDateInput);
Vue.filter('convertDateOutput', convertDateOutput);
Vue.filter('convertDatetimeFormat', convertDatetimeFormat);
Vue.filter('dateFormatValid', dateFormatValid);
Vue.filter('replaceSpecialChars', replaceSpecialChars);
Vue.filter('dataHoje', dataHoje);
Vue.filter('unixToDate', unixToDate);
Vue.filter('dataCompleta', dataCompleta);
Vue.filter('horaFormatada', horaFormatada);
Vue.filter('diaSemanaAbreviado', diaSemanaAbreviado);
Vue.filter('calcularFaixaEtaria', calcularFaixaEtaria);

export default {
  propostaMap,
  removeSymbols,
  getAge,
  convertDateInput,
  convertDateOutput,
  mapBeneficiario,
  convertDatetimeFormat,
  dateFormatValid,
  propostaToJson,
  replaceSpecialChars,
  dataHoje,
  unixToDate,
  dataCompleta,
  horaFormatada,
  diaSemanaAbreviado,
  calcularFaixaEtaria,
  etapaAdesaoPme
};
